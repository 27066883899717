import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';

import { bgBlur } from 'src/theme/css';
import Logo from 'src/components/logo';
import Logo_black from 'src/components/logo_black';
import Logo_black_mini from 'src/components/logo_black_mini';
import Logo_black_midi from 'src/components/logo_black_midi';

import { useResponsive } from 'src/hooks/use-responsive';
import { useOffSetTop } from 'src/hooks/use-off-set-top';

import { HEADER } from '../config-layout';

import HeaderShadow from '../common/header-shadow';

import NavMobile from './nav/mobile';

import { navConfig } from './config-navigation';
import { useLocation } from 'react-router-dom';


// ----------------------------------------------------------------------

export default function Header({ headerOnDark }) {
  const theme = useTheme();

  const offset = useOffSetTop();

  const mdUp = useResponsive('up', 'md');
  const location = useLocation();
  const validPaths = ['/' , '/leistungen/branding' , '/leistungen/branding/' , '/leistungen/webdesign' , '/leistungen/webdesign/', '/leistungen/grafikdesign' , '/leistungen/grafikdesign/', '/leistungen/ecommerce' , '/leistungen/ecommerce/' , '/leistungen/webdesign/website-relaunch' , '/leistungen/webdesign/website-relaunch/', '/beratung/ki-strategie', '/beratung/ki-strategie/', '/beratung/digitalisierung','/beratung/digitalisierung/', '/beratung/projekt-management','/beratung/projekt-management/'];
  const isAboutRoute = validPaths.includes(location.pathname); 


  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MOBILE,
          },
          
            transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.standard,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(offset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: 'text.primary',
            height: {
              xs: HEADER.H_MOBILE - 40,
              md: HEADER.H_DESKTOP - 75,
            },
            
          }),
        }}
      >
        <Container
          sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box sx={{ lineHeight: 0, position: 'relative' }}>


             {
                isAboutRoute ? 
                (
                  offset && !mdUp ? <Logo_black_mini /> : offset ? <Logo_black_midi /> : <Logo />
                ) :
                (
                  offset && !mdUp ? <Logo_black_mini /> : offset ? <Logo_black_midi /> : <Logo_black />
                )
            }


          </Box>

    

          <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
            <Stack spacing={1} direction="row" alignItems="center">
           

            </Stack>


          </Stack>

          <NavMobile data={navConfig} />
        </Container>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}

Header.propTypes = {
  headerOnDark: PropTypes.bool,
};
