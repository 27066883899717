import 'src/global.css';

// ----------------------------------------------------------------------

import ThemeProvider from 'src/theme';
import Router from 'src/routes/sections';
import { LocalizationProvider } from 'src/locales';
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';


// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <LocalizationProvider>

        <ThemeProvider>
          <MotionLazy>
            <ProgressBar />

            <Router />
          </MotionLazy>
        </ThemeProvider>

    </LocalizationProvider>
  );
}
