import { paths } from 'src/routes/paths';

export const pageLinks = [
  {
    subheader: 'Agentur',
    cover: '/assets/images/menu/menu_career.jpg',
    items: [
      { title: 'Über Mich', path: paths.agentur.about },
      { title: 'Jobs', path: paths.agentur.jobs },
      { title: 'Kontakt', path: paths.agentur.kontakt },
    ],
  },
  {
    subheader: 'Leistungen',
    cover: '/assets/images/menu/menu_marketing.jpg',
    items: [
      { title: 'Branding', path: paths.services.branding },
      { title: 'Web Design', path: paths.services.webdesign },
      { title: 'Website Relaunch', path: paths.services.relaunch },
      { title: 'Grafik Design', path: paths.services.grafikdesign },
      { title: 'E-Commerce', path: paths.services.ecommerce },
    ],
  },
  {
    subheader: 'Beratung',
    cover: '/assets/images/menu/menu_marketing.jpg',
    items: [
      { title: 'Digitalisierung', path: paths.consulting.digitalization },
      { title: 'KI-Strategie', path: paths.consulting.ai },
      { title: 'Projektmanagement', path: paths.consulting.pm },
    ],
  },
  {
    subheader: 'Case Studies',
    cover: '/assets/images/menu/menu_travel.jpg',
    items: [
      { title: 'Classic Company', path: paths.agentur.casestudy_cc },
      { title: 'Eiscafe Rimini', path: paths.agentur.casestudy_rimini},
    ],
  },
];

export const navConfig = pageLinks.map(link => ({
  title: link.subheader,
  path: paths.pages,
  children: [{ items: link.items }],
}));
