import { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

function Logo({ single = false, sx }) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const singleLogo = (
<svg width="128" height="78" xmlns="http://www.w3.org/2000/svg">
 <defs>
  <linearGradient y2="0.5" x2="1.00001" y1="0.5" x1="-0.00001" id="Adsız_degrade_344">
   <stop stop-color="#00ffe0" offset="0"/>
   <stop stop-color="#09f" offset="0.55"/>
   <stop stop-color="#167aff" offset="0.65"/>
   <stop stop-color="#4e2eff" offset="0.87"/>
   <stop stop-color="#7000ff" offset="1"/>
  </linearGradient>
 </defs>
 <g>
  <title>Layer 1</title>
  <g stroke="null" id="svg_14">
   <g stroke="null" id="svg_1">
    <g stroke="null" id="svg_2">
     <polygon stroke="null" id="svg_3" fill="#1e1e1e" points="18.882237679045858,47.333542898235265 18.882237679045858,51.217582151401785 1.5061438620470256,51.217582151401785 1.5061438620470256,47.333542898235265 12.8613743678317,31.592796462361804 1.7786960629508997,31.592796462361804 1.7786960629508997,27.708761114989812 18.610062387314002,27.708761114989812 18.610062387314002,31.592796462361804 7.288458819524095,47.333542898235265 18.882237679045858,47.333542898235265 " class="cls-2"/>
     <path stroke="null" id="svg_4" fill="#1e1e1e" d="m38.3028,47.19728l0.23857,3.78175c-1.0902,0.09122 -2.26055,0.16508 -3.50919,0.22159c-1.24938,0.05651 -2.52128,0.09048 -3.81609,0.1023c-1.29444,0.01108 -2.57816,0.01699 -3.85006,0.01699c-1.49903,0 -2.70853,-0.43726 -3.62848,-1.31179c-0.91996,-0.87416 -1.37975,-2.0157 -1.37975,-3.42425l0,-14.24136c0,-1.43108 0.45979,-2.57779 1.37975,-3.44124c0.91996,-0.86307 2.12944,-1.29481 3.62848,-1.29481c1.27191,0 2.55563,0.00591 3.85006,0.01699c1.29481,0.01181 2.56671,0.03988 3.81609,0.08531c1.24864,0.0458 2.41898,0.12519 3.50919,0.23857l-0.23857,3.78175l-9.02854,0c-0.568,0 -0.99418,0.14809 -1.27781,0.44281c-0.28437,0.29544 -0.42581,0.72754 -0.42581,1.29481l0,3.67981l9.47172,0l0,3.67944l-9.47172,0l0,4.63374c0,0.568 0.14145,0.99972 0.42581,1.29481c0.28363,0.29544 0.70981,0.44281 1.27781,0.44281l9.02854,0z" class="cls-2"/>
     <path stroke="null" id="svg_5" fill="#1e1e1e" d="m59.15335,40.94522c-0.72718,-1.05623 -1.99908,-1.66375 -3.81572,-1.82291l0,-0.20423c1.47613,-0.13628 2.53828,-0.6415 3.18568,-1.51603c0.64703,-0.87416 0.97092,-2.15197 0.97092,-3.83307s-0.27256,-2.86216 -0.81766,-3.74776c-0.5451,-0.88597 -1.4259,-1.50457 -2.64057,-1.8569c-1.2154,-0.35195 -2.8448,-0.52812 -4.88931,-0.52812c-1.34022,0 -2.50984,0.00591 -3.50919,0.01699c-0.99972,0.01181 -1.92485,0.03397 -2.77685,0.06832c-0.85163,0.03397 -1.73207,0.09675 -2.6402,0.18724l0,23.50884c0.99898,0.06795 1.97581,0.12445 2.92974,0.17025c0.9543,0.04543 1.99317,0.0735 3.11772,0.08531c1.12418,0.01108 2.44705,0.01699 3.96898,0.01699c1.88534,0 3.41243,-0.17616 4.58278,-0.52812c1.1696,-0.35195 2.03232,-0.97092 2.58923,-1.8569c0.55655,-0.8856 0.83464,-2.13461 0.83464,-3.74776c0,-1.88497 -0.3634,-3.35593 -1.0902,-4.41216l0,0.00002zm-8.2792,-9.72728c0.93103,0 1.65784,0.07977 2.18078,0.23857c0.5222,0.15918 0.89114,0.45979 1.10719,0.90297c0.21568,0.44281 0.32351,1.08503 0.32351,1.92485s-0.10193,1.45397 -0.30653,1.90822c-0.2046,0.45462 -0.55101,0.76115 -1.03924,0.91958c-0.4886,0.15918 -1.15299,0.25039 -1.99317,0.27256l-3.81572,0l0,-6.16675l3.54317,0l0,-0.00001zm3.83307,15.21264c-0.21605,0.4886 -0.59644,0.82356 -1.14154,1.00527c-0.5451,0.18207 -1.29444,0.27256 -2.24836,0.27256c-1.45582,0 -2.78461,-0.00554 -3.98634,-0.01699l0,-7.00176l3.88404,0c0.97645,0.0229 1.74277,0.13628 2.2997,0.34087c0.55619,0.20423 0.94802,0.56209 1.17552,1.07322c0.22676,0.51113 0.3405,1.25528 0.3405,2.23138c0,0.90887 -0.10784,1.60724 -0.32351,2.09547l0,-0.00001z" class="cls-2"/>
     <path stroke="null" id="svg_6" fill="#1e1e1e" d="m80.68601,43.89232c-0.18207,-0.81766 -0.4657,-1.47023 -0.85163,-1.95919c-0.38667,-0.48786 -0.80066,-0.8568 -1.24347,-1.10719c-0.44318,-0.24966 -0.86899,-0.40882 -1.27781,-0.47678l0,-0.17062c1.13563,-0.13628 2.03269,-0.45388 2.69154,-0.95393c0.65848,-0.49931 1.12972,-1.1637 1.41409,-1.99317c0.28363,-0.82873 0.42581,-1.78857 0.42581,-2.87878c0,-1.79411 -0.30136,-3.19675 -0.90297,-4.20793c-0.60197,-1.01044 -1.54483,-1.72579 -2.82781,-2.14644c-1.28335,-0.41991 -2.95892,-0.63041 -5.02521,-0.63041c-1.40892,0 -2.6125,0.00591 -3.61186,0.01736c-0.99972,0.01144 -1.91968,0.03988 -2.75949,0.08494c-0.84055,0.0458 -1.7376,0.12519 -2.69154,0.23857l0,23.50884l5.1445,0l0,-9.21393c1.02151,0.01034 2.25908,0.01477 3.71379,0.01477c0.70391,0 1.25491,0.14809 1.65229,0.44281c0.39738,0.29544 0.69874,0.82947 0.90297,1.60134l1.80593,7.15503l5.48537,0l-2.0445,-7.32528l0.00001,-0.00001zm-4.41216,-7.12067c-0.21605,0.49968 -0.5909,0.84646 -1.12418,1.03924c-0.53402,0.19315 -1.25528,0.28954 -2.16342,0.28954l-3.81609,0l0,-6.79901c1.12676,-0.00997 2.39904,-0.01514 3.81609,-0.01514c0.90814,0 1.62939,0.09675 2.16342,0.28954c0.53329,0.19315 0.90814,0.53402 1.12418,1.02225c0.21568,0.4886 0.32388,1.18697 0.32388,2.0951s-0.10821,1.57917 -0.32388,2.07848z" class="cls-2"/>
     <path stroke="null" id="svg_7" fill="#1e1e1e" d="m99.11793,29.07151c-0.11375,-0.40882 -0.33534,-0.73788 -0.66439,-0.98791c-0.32943,-0.24966 -0.71018,-0.37485 -1.14117,-0.37485l-4.94026,0c-0.43172,0 -0.81249,0.12519 -1.14154,0.37485c-0.32943,0.25002 -0.55101,0.57907 -0.66439,0.98791l-6.84813,22.14608l5.3491,0l1.72432,-6.20073l8.1012,0l1.72432,6.20073l5.31512,0l-6.81416,-22.14608l0.00001,0zm-7.23664,12.02697l1.98985,-7.15466c0.11338,-0.38593 0.20976,-0.77776 0.28954,-1.17552c0.0794,-0.39701 0.15326,-0.77776 0.22159,-1.14154l0.91996,0c0.06795,0.36377 0.14735,0.74453 0.2382,1.14154c0.09048,0.39775 0.1817,0.78959 0.27292,1.17552l1.98948,7.15466l-5.92153,0z" class="cls-2"/>
    </g>
    <g stroke="null" id="svg_8">
     <path stroke="null" id="svg_9" fill="#1e1e1e" d="m13.28422,71.41113c0,1.14856 -0.26923,2.16047 -0.80658,3.03537c-0.53809,0.87527 -1.27634,1.55886 -2.21549,2.05116c-0.93916,0.49229 -2.01939,0.73862 -3.2407,0.73862s-2.26535,-0.23746 -3.2407,-0.71129c-0.97535,-0.47382 -1.79116,-1.15742 -2.44742,-2.05116l0.98421,-0.95688c0.49229,0.74748 1.14893,1.33099 1.96916,1.75016c0.8206,0.41954 1.72283,0.62894 2.70741,0.62894s1.80926,-0.18688 2.52978,-0.56062c0.72015,-0.37337 1.28077,-0.9026 1.68185,-1.58619c0.40107,-0.6836 0.60161,-1.47207 0.60161,-2.3658s-0.20053,-1.7317 -0.60161,-2.40643c-0.40107,-0.67437 -0.96648,-1.20321 -1.6955,-1.58619c-0.72939,-0.38298 -1.59542,-0.57427 -2.5981,-0.57427c-0.29176,0 -0.59274,0.01847 -0.9026,0.05466c-0.31023,0.03656 -0.62894,0.10045 -0.95688,0.1913l0,-0.90223l5.90454,-6.56376l-8.85829,0l0,-1.2852l10.96669,0l0,0.92956l-5.9496,6.5272c0.15326,-0.01219 0.31317,-0.0181 0.47973,-0.0181c1.0758,0 2.04192,0.2419 2.89909,0.72459c0.8568,0.48343 1.5356,1.15336 2.03749,2.01016c0.50116,0.85717 0.75192,1.83215 0.75192,2.92642l0,-0.00001z" class="cls-2"/>
     <path stroke="null" id="svg_10" fill="#1e1e1e" d="m28.06588,67.78746c-0.55655,-0.9207 -1.29481,-1.65895 -2.21549,-2.21549c-0.9207,-0.55582 -1.93741,-0.8339 -3.04903,-0.8339c-0.47456,0 -0.92993,0.05466 -1.36756,0.16398c-0.41548,0.10378 -0.79402,0.24892 -1.13489,0.43468l5.45583,-7.02539l-1.72283,0l-6.26277,8.25889c-1.05771,1.40449 -1.58619,2.87176 -1.58619,4.40329c0,1.16702 0.28253,2.22436 0.84793,3.17238c0.56467,0.94802 1.32619,1.7003 2.28345,2.25612c0.95725,0.55655 2.01902,0.83427 3.18605,0.83427s2.26055,-0.28253 3.22703,-0.84793c0.96611,-0.56504 1.7365,-1.33062 2.31078,-2.29712c0.57464,-0.96611 0.8616,-2.02382 0.8616,-3.17238s-0.27809,-2.21069 -0.8339,-3.13138l0.00001,-0.00001zm-1.28557,5.63346c-0.44686,0.75709 -1.04404,1.36756 -1.79116,1.83252c-0.74785,0.46496 -1.56847,0.69725 -2.46145,0.69725s-1.7365,-0.22306 -2.47512,-0.66993c-0.73825,-0.44649 -1.32619,-1.05291 -1.76383,-1.81848c-0.43764,-0.76595 -0.65627,-1.61389 -0.65627,-2.54345s0.21863,-1.79559 0.65627,-2.54345c0.43764,-0.74748 1.02558,-1.34429 1.76383,-1.79153c0.73862,-0.44649 1.5633,-0.66993 2.47512,-0.66993s1.7136,0.22343 2.46145,0.66993c0.74712,0.44724 1.34429,1.05291 1.79116,1.81886c0.44649,0.76558 0.66993,1.60465 0.66993,2.51611s-0.22343,1.74573 -0.66993,2.50208l0,0.00001z" class="cls-2"/>
     <path stroke="null" id="svg_11" fill="#1e1e1e" d="m45.66354,62.5366c-0.65627,-1.43108 -1.54483,-2.53864 -2.66643,-3.32306c-1.12123,-0.78368 -2.37504,-1.17589 -3.76032,-1.17589s-2.58443,0.39221 -3.70566,1.17589c-1.12123,0.78442 -2.01016,1.88718 -2.66643,3.3094c-0.65627,1.42185 -0.98458,3.11772 -0.98458,5.08651s0.33238,3.64694 0.99825,5.08688c0.6655,1.44032 1.5633,2.55711 2.69376,3.35001c1.13046,0.79328 2.37947,1.18992 3.74666,1.18992s2.58,-0.39184 3.692,-1.17626c1.11199,-0.78368 1.99649,-1.89604 2.65276,-3.33635c0.65664,-1.43994 0.98458,-3.13545 0.98458,-5.08688s-0.32794,-3.66874 -0.98458,-5.10018l0,0.00001zm-1.24421,9.5308c-0.51962,1.24014 -1.22648,2.18337 -2.11947,2.83039c-0.89336,0.6474 -1.90528,0.97092 -3.03574,0.97092s-2.14237,-0.32351 -3.03537,-0.97092c-0.89373,-0.64703 -1.59985,-1.59025 -2.11947,-2.83039c-0.51999,-1.23977 -0.77962,-2.72551 -0.77962,-4.45795s0.25963,-3.20857 0.77962,-4.43025c0.51962,-1.22131 1.22574,-2.15567 2.11947,-2.80307c0.893,-0.64703 1.90491,-0.97092 3.03537,-0.97092s2.14237,0.32388 3.03574,0.97092c0.893,0.6474 1.59985,1.58619 2.11947,2.81674c0.51962,1.23054 0.77962,2.70298 0.77962,4.41659s-0.26,3.21817 -0.77962,4.45795l0,-0.00001z" class="cls-2"/>
    </g>
   </g>
   <path stroke="null" id="svg_12" fill="url(#Adsız_degrade_344)" d="m114.01704,77.23554l-5.33876,0s-6.75691,-22.10066 -6.75691,-22.10066l5.30293,0.00074l6.79273,22.1003l0,-0.00037l0,-0.00001zm-23.27876,-75.9011l-5.30293,-0.00074l6.75691,22.10066l5.33876,0s-6.79273,-22.1003 -6.79273,-22.1003l0,0.00037l0,0.00001zm23.38623,-0.00037l-5.33876,0l14.32778,46.86405l2.68489,-8.68065l-11.67391,-38.1834zm-18.59405,53.80118l-5.30293,-0.00074l6.75691,22.10066l5.33876,0s-6.79273,-22.1003 -6.79273,-22.1003l0,0.00037l0,0.00001zm-16.48603,-53.80118l-5.30293,-0.00074l6.75691,22.10066l5.33876,0s-6.79273,-22.1003 -6.79273,-22.1003l0,0.00037l0,0.00001zm4.79217,53.80118l-5.30293,-0.00074l6.75691,22.10066l5.33876,0s-6.79273,-22.1003 -6.79273,-22.1003l0,0.00037l0,0.00001zm-16.48603,-53.80118l-5.30293,-0.00074l6.75691,22.10066l5.33876,0s-6.79273,-22.1003 -6.79273,-22.1003l0,0.00037l0,0.00001zm35.11776,0l-5.33876,0l20.12226,65.81707l2.68489,-8.68028l0,0s-17.46839,-57.1368 -17.46839,-57.1368l0,0.00001z" class="cls-1"/>
  </g>
 </g>
</svg>
  );

  const fullLogo = (
<svg width="128" height="78" xmlns="http://www.w3.org/2000/svg">
 <defs>
  <linearGradient y2="0.5" x2="1.00001" y1="0.5" x1="-0.00001" id="Adsız_degrade_344">
   <stop stop-color="#00ffe0" offset="0"/>
   <stop stop-color="#09f" offset="0.55"/>
   <stop stop-color="#167aff" offset="0.65"/>
   <stop stop-color="#4e2eff" offset="0.87"/>
   <stop stop-color="#7000ff" offset="1"/>
  </linearGradient>
 </defs>
 <g>
  <title>Layer 1</title>
  <g stroke="null" id="svg_14">
   <g stroke="null" id="svg_1">
    <g stroke="null" id="svg_2">
     <polygon stroke="null" id="svg_3" fill="#1e1e1e" points="18.882237679045858,47.333542898235265 18.882237679045858,51.217582151401785 1.5061438620470256,51.217582151401785 1.5061438620470256,47.333542898235265 12.8613743678317,31.592796462361804 1.7786960629508997,31.592796462361804 1.7786960629508997,27.708761114989812 18.610062387314002,27.708761114989812 18.610062387314002,31.592796462361804 7.288458819524095,47.333542898235265 18.882237679045858,47.333542898235265 " class="cls-2"/>
     <path stroke="null" id="svg_4" fill="#1e1e1e" d="m38.3028,47.19728l0.23857,3.78175c-1.0902,0.09122 -2.26055,0.16508 -3.50919,0.22159c-1.24938,0.05651 -2.52128,0.09048 -3.81609,0.1023c-1.29444,0.01108 -2.57816,0.01699 -3.85006,0.01699c-1.49903,0 -2.70853,-0.43726 -3.62848,-1.31179c-0.91996,-0.87416 -1.37975,-2.0157 -1.37975,-3.42425l0,-14.24136c0,-1.43108 0.45979,-2.57779 1.37975,-3.44124c0.91996,-0.86307 2.12944,-1.29481 3.62848,-1.29481c1.27191,0 2.55563,0.00591 3.85006,0.01699c1.29481,0.01181 2.56671,0.03988 3.81609,0.08531c1.24864,0.0458 2.41898,0.12519 3.50919,0.23857l-0.23857,3.78175l-9.02854,0c-0.568,0 -0.99418,0.14809 -1.27781,0.44281c-0.28437,0.29544 -0.42581,0.72754 -0.42581,1.29481l0,3.67981l9.47172,0l0,3.67944l-9.47172,0l0,4.63374c0,0.568 0.14145,0.99972 0.42581,1.29481c0.28363,0.29544 0.70981,0.44281 1.27781,0.44281l9.02854,0z" class="cls-2"/>
     <path stroke="null" id="svg_5" fill="#1e1e1e" d="m59.15335,40.94522c-0.72718,-1.05623 -1.99908,-1.66375 -3.81572,-1.82291l0,-0.20423c1.47613,-0.13628 2.53828,-0.6415 3.18568,-1.51603c0.64703,-0.87416 0.97092,-2.15197 0.97092,-3.83307s-0.27256,-2.86216 -0.81766,-3.74776c-0.5451,-0.88597 -1.4259,-1.50457 -2.64057,-1.8569c-1.2154,-0.35195 -2.8448,-0.52812 -4.88931,-0.52812c-1.34022,0 -2.50984,0.00591 -3.50919,0.01699c-0.99972,0.01181 -1.92485,0.03397 -2.77685,0.06832c-0.85163,0.03397 -1.73207,0.09675 -2.6402,0.18724l0,23.50884c0.99898,0.06795 1.97581,0.12445 2.92974,0.17025c0.9543,0.04543 1.99317,0.0735 3.11772,0.08531c1.12418,0.01108 2.44705,0.01699 3.96898,0.01699c1.88534,0 3.41243,-0.17616 4.58278,-0.52812c1.1696,-0.35195 2.03232,-0.97092 2.58923,-1.8569c0.55655,-0.8856 0.83464,-2.13461 0.83464,-3.74776c0,-1.88497 -0.3634,-3.35593 -1.0902,-4.41216l0,0.00002zm-8.2792,-9.72728c0.93103,0 1.65784,0.07977 2.18078,0.23857c0.5222,0.15918 0.89114,0.45979 1.10719,0.90297c0.21568,0.44281 0.32351,1.08503 0.32351,1.92485s-0.10193,1.45397 -0.30653,1.90822c-0.2046,0.45462 -0.55101,0.76115 -1.03924,0.91958c-0.4886,0.15918 -1.15299,0.25039 -1.99317,0.27256l-3.81572,0l0,-6.16675l3.54317,0l0,-0.00001zm3.83307,15.21264c-0.21605,0.4886 -0.59644,0.82356 -1.14154,1.00527c-0.5451,0.18207 -1.29444,0.27256 -2.24836,0.27256c-1.45582,0 -2.78461,-0.00554 -3.98634,-0.01699l0,-7.00176l3.88404,0c0.97645,0.0229 1.74277,0.13628 2.2997,0.34087c0.55619,0.20423 0.94802,0.56209 1.17552,1.07322c0.22676,0.51113 0.3405,1.25528 0.3405,2.23138c0,0.90887 -0.10784,1.60724 -0.32351,2.09547l0,-0.00001z" class="cls-2"/>
     <path stroke="null" id="svg_6" fill="#1e1e1e" d="m80.68601,43.89232c-0.18207,-0.81766 -0.4657,-1.47023 -0.85163,-1.95919c-0.38667,-0.48786 -0.80066,-0.8568 -1.24347,-1.10719c-0.44318,-0.24966 -0.86899,-0.40882 -1.27781,-0.47678l0,-0.17062c1.13563,-0.13628 2.03269,-0.45388 2.69154,-0.95393c0.65848,-0.49931 1.12972,-1.1637 1.41409,-1.99317c0.28363,-0.82873 0.42581,-1.78857 0.42581,-2.87878c0,-1.79411 -0.30136,-3.19675 -0.90297,-4.20793c-0.60197,-1.01044 -1.54483,-1.72579 -2.82781,-2.14644c-1.28335,-0.41991 -2.95892,-0.63041 -5.02521,-0.63041c-1.40892,0 -2.6125,0.00591 -3.61186,0.01736c-0.99972,0.01144 -1.91968,0.03988 -2.75949,0.08494c-0.84055,0.0458 -1.7376,0.12519 -2.69154,0.23857l0,23.50884l5.1445,0l0,-9.21393c1.02151,0.01034 2.25908,0.01477 3.71379,0.01477c0.70391,0 1.25491,0.14809 1.65229,0.44281c0.39738,0.29544 0.69874,0.82947 0.90297,1.60134l1.80593,7.15503l5.48537,0l-2.0445,-7.32528l0.00001,-0.00001zm-4.41216,-7.12067c-0.21605,0.49968 -0.5909,0.84646 -1.12418,1.03924c-0.53402,0.19315 -1.25528,0.28954 -2.16342,0.28954l-3.81609,0l0,-6.79901c1.12676,-0.00997 2.39904,-0.01514 3.81609,-0.01514c0.90814,0 1.62939,0.09675 2.16342,0.28954c0.53329,0.19315 0.90814,0.53402 1.12418,1.02225c0.21568,0.4886 0.32388,1.18697 0.32388,2.0951s-0.10821,1.57917 -0.32388,2.07848z" class="cls-2"/>
     <path stroke="null" id="svg_7" fill="#1e1e1e" d="m99.11793,29.07151c-0.11375,-0.40882 -0.33534,-0.73788 -0.66439,-0.98791c-0.32943,-0.24966 -0.71018,-0.37485 -1.14117,-0.37485l-4.94026,0c-0.43172,0 -0.81249,0.12519 -1.14154,0.37485c-0.32943,0.25002 -0.55101,0.57907 -0.66439,0.98791l-6.84813,22.14608l5.3491,0l1.72432,-6.20073l8.1012,0l1.72432,6.20073l5.31512,0l-6.81416,-22.14608l0.00001,0zm-7.23664,12.02697l1.98985,-7.15466c0.11338,-0.38593 0.20976,-0.77776 0.28954,-1.17552c0.0794,-0.39701 0.15326,-0.77776 0.22159,-1.14154l0.91996,0c0.06795,0.36377 0.14735,0.74453 0.2382,1.14154c0.09048,0.39775 0.1817,0.78959 0.27292,1.17552l1.98948,7.15466l-5.92153,0z" class="cls-2"/>
    </g>
    <g stroke="null" id="svg_8">
     <path stroke="null" id="svg_9" fill="#1e1e1e" d="m13.28422,71.41113c0,1.14856 -0.26923,2.16047 -0.80658,3.03537c-0.53809,0.87527 -1.27634,1.55886 -2.21549,2.05116c-0.93916,0.49229 -2.01939,0.73862 -3.2407,0.73862s-2.26535,-0.23746 -3.2407,-0.71129c-0.97535,-0.47382 -1.79116,-1.15742 -2.44742,-2.05116l0.98421,-0.95688c0.49229,0.74748 1.14893,1.33099 1.96916,1.75016c0.8206,0.41954 1.72283,0.62894 2.70741,0.62894s1.80926,-0.18688 2.52978,-0.56062c0.72015,-0.37337 1.28077,-0.9026 1.68185,-1.58619c0.40107,-0.6836 0.60161,-1.47207 0.60161,-2.3658s-0.20053,-1.7317 -0.60161,-2.40643c-0.40107,-0.67437 -0.96648,-1.20321 -1.6955,-1.58619c-0.72939,-0.38298 -1.59542,-0.57427 -2.5981,-0.57427c-0.29176,0 -0.59274,0.01847 -0.9026,0.05466c-0.31023,0.03656 -0.62894,0.10045 -0.95688,0.1913l0,-0.90223l5.90454,-6.56376l-8.85829,0l0,-1.2852l10.96669,0l0,0.92956l-5.9496,6.5272c0.15326,-0.01219 0.31317,-0.0181 0.47973,-0.0181c1.0758,0 2.04192,0.2419 2.89909,0.72459c0.8568,0.48343 1.5356,1.15336 2.03749,2.01016c0.50116,0.85717 0.75192,1.83215 0.75192,2.92642l0,-0.00001z" class="cls-2"/>
     <path stroke="null" id="svg_10" fill="#1e1e1e" d="m28.06588,67.78746c-0.55655,-0.9207 -1.29481,-1.65895 -2.21549,-2.21549c-0.9207,-0.55582 -1.93741,-0.8339 -3.04903,-0.8339c-0.47456,0 -0.92993,0.05466 -1.36756,0.16398c-0.41548,0.10378 -0.79402,0.24892 -1.13489,0.43468l5.45583,-7.02539l-1.72283,0l-6.26277,8.25889c-1.05771,1.40449 -1.58619,2.87176 -1.58619,4.40329c0,1.16702 0.28253,2.22436 0.84793,3.17238c0.56467,0.94802 1.32619,1.7003 2.28345,2.25612c0.95725,0.55655 2.01902,0.83427 3.18605,0.83427s2.26055,-0.28253 3.22703,-0.84793c0.96611,-0.56504 1.7365,-1.33062 2.31078,-2.29712c0.57464,-0.96611 0.8616,-2.02382 0.8616,-3.17238s-0.27809,-2.21069 -0.8339,-3.13138l0.00001,-0.00001zm-1.28557,5.63346c-0.44686,0.75709 -1.04404,1.36756 -1.79116,1.83252c-0.74785,0.46496 -1.56847,0.69725 -2.46145,0.69725s-1.7365,-0.22306 -2.47512,-0.66993c-0.73825,-0.44649 -1.32619,-1.05291 -1.76383,-1.81848c-0.43764,-0.76595 -0.65627,-1.61389 -0.65627,-2.54345s0.21863,-1.79559 0.65627,-2.54345c0.43764,-0.74748 1.02558,-1.34429 1.76383,-1.79153c0.73862,-0.44649 1.5633,-0.66993 2.47512,-0.66993s1.7136,0.22343 2.46145,0.66993c0.74712,0.44724 1.34429,1.05291 1.79116,1.81886c0.44649,0.76558 0.66993,1.60465 0.66993,2.51611s-0.22343,1.74573 -0.66993,2.50208l0,0.00001z" class="cls-2"/>
     <path stroke="null" id="svg_11" fill="#1e1e1e" d="m45.66354,62.5366c-0.65627,-1.43108 -1.54483,-2.53864 -2.66643,-3.32306c-1.12123,-0.78368 -2.37504,-1.17589 -3.76032,-1.17589s-2.58443,0.39221 -3.70566,1.17589c-1.12123,0.78442 -2.01016,1.88718 -2.66643,3.3094c-0.65627,1.42185 -0.98458,3.11772 -0.98458,5.08651s0.33238,3.64694 0.99825,5.08688c0.6655,1.44032 1.5633,2.55711 2.69376,3.35001c1.13046,0.79328 2.37947,1.18992 3.74666,1.18992s2.58,-0.39184 3.692,-1.17626c1.11199,-0.78368 1.99649,-1.89604 2.65276,-3.33635c0.65664,-1.43994 0.98458,-3.13545 0.98458,-5.08688s-0.32794,-3.66874 -0.98458,-5.10018l0,0.00001zm-1.24421,9.5308c-0.51962,1.24014 -1.22648,2.18337 -2.11947,2.83039c-0.89336,0.6474 -1.90528,0.97092 -3.03574,0.97092s-2.14237,-0.32351 -3.03537,-0.97092c-0.89373,-0.64703 -1.59985,-1.59025 -2.11947,-2.83039c-0.51999,-1.23977 -0.77962,-2.72551 -0.77962,-4.45795s0.25963,-3.20857 0.77962,-4.43025c0.51962,-1.22131 1.22574,-2.15567 2.11947,-2.80307c0.893,-0.64703 1.90491,-0.97092 3.03537,-0.97092s2.14237,0.32388 3.03574,0.97092c0.893,0.6474 1.59985,1.58619 2.11947,2.81674c0.51962,1.23054 0.77962,2.70298 0.77962,4.41659s-0.26,3.21817 -0.77962,4.45795l0,-0.00001z" class="cls-2"/>
    </g>
   </g>
   <path stroke="null" id="svg_12" fill="url(#Adsız_degrade_344)" d="m114.01704,77.23554l-5.33876,0s-6.75691,-22.10066 -6.75691,-22.10066l5.30293,0.00074l6.79273,22.1003l0,-0.00037l0,-0.00001zm-23.27876,-75.9011l-5.30293,-0.00074l6.75691,22.10066l5.33876,0s-6.79273,-22.1003 -6.79273,-22.1003l0,0.00037l0,0.00001zm23.38623,-0.00037l-5.33876,0l14.32778,46.86405l2.68489,-8.68065l-11.67391,-38.1834zm-18.59405,53.80118l-5.30293,-0.00074l6.75691,22.10066l5.33876,0s-6.79273,-22.1003 -6.79273,-22.1003l0,0.00037l0,0.00001zm-16.48603,-53.80118l-5.30293,-0.00074l6.75691,22.10066l5.33876,0s-6.79273,-22.1003 -6.79273,-22.1003l0,0.00037l0,0.00001zm4.79217,53.80118l-5.30293,-0.00074l6.75691,22.10066l5.33876,0s-6.79273,-22.1003 -6.79273,-22.1003l0,0.00037l0,0.00001zm-16.48603,-53.80118l-5.30293,-0.00074l6.75691,22.10066l5.33876,0s-6.79273,-22.1003 -6.79273,-22.1003l0,0.00037l0,0.00001zm35.11776,0l-5.33876,0l20.12226,65.81707l2.68489,-8.68028l0,0s-17.46839,-57.1368 -17.46839,-57.1368l0,0.00001z" class="cls-1"/>
  </g>
 </g>
</svg>
  );

  return (
    <Link
      component={RouterLink}
      href="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: single ? 78 : 128,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        {single ? singleLogo : fullLogo}
      </Box>
    </Link>
  );
}

Logo.propTypes = {
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(Logo);
