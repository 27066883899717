import { lazy } from 'react';

// ----------------------------------------------------------------------

const AboutPage = lazy(() => import('src/pages/agentur/about'));
const JobsPage = lazy(() => import('src/pages/agentur/jobs'));
const ContactPage = lazy(() => import('src/pages/agentur/contact'));
const FullstackPage = lazy(() => import('src/pages/agentur/fullstack'));
const CaseStudyPage = lazy(() => import('src/pages/agentur/case-study'));
const ImprintPage = lazy(() => import('src/pages/agentur/imprint'));
const PrivacyPage = lazy(() => import('src/pages/agentur/privacy'));



// ----------------------------------------------------------------------

export const agenturRoutes = [
  {
    path: 'agentur',
    children: [
      { path: 'ueber-mich', element: <AboutPage /> },
      { path: 'jobs', element: <JobsPage /> },
      { path: 'kontakt', element: <ContactPage /> },
      { path: 'jobs/fullstack-webentwickler', element: <FullstackPage /> },
      { path: 'case-study/classic-company', element: <CaseStudyPage /> },
      { path: 'case-study/eiscaferimini', element: <CaseStudyPage /> },
      { path: 'impressum', element: <ImprintPage /> },
      { path: 'datenschutz', element: <PrivacyPage /> },

    ],
  },
];
