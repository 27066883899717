import { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

function Logo({ single = false, sx }) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const singleLogo = (
<svg width="64" height="39" xmlns="http://www.w3.org/2000/svg">
 <defs>
  <linearGradient y2="0.5" x2="1.00001" y1="0.5" x1="-0.00001" id="Adsız_degrade_344">
   <stop stop-color="#00ffe0" offset="0"/>
   <stop stop-color="#09f" offset="0.55"/>
   <stop stop-color="#167aff" offset="0.65"/>
   <stop stop-color="#4e2eff" offset="0.87"/>
   <stop stop-color="#7000ff" offset="1"/>
  </linearGradient>
 </defs>
 <g>
  <title>Layer 1</title>
  <g stroke="null" id="svg_13">
   <g stroke="null" id="svg_1">
    <g stroke="null" id="svg_2">
     <polygon stroke="null" id="svg_3" fill="#1e1e1e" points="10.195943504571915,23.611576080322266 10.195943504571915,25.51486587524414 1.6811658442020416,25.51486587524414 1.6811658442020416,23.611576080322266 7.245550781488419,15.898164749145508 1.8147243559360504,15.898164749145508 1.8147243559360504,13.99487590789795 10.062570244073868,13.99487590789795 10.062570244073868,15.898164749145508 4.514664322137833,23.611576080322266 10.195943504571915,23.611576080322266 " class="cls-2"/>
     <path stroke="null" id="svg_4" fill="#1e1e1e" d="m19.71257,23.5448l0.11691,1.85316c-0.53423,0.0447 -1.10773,0.08089 -1.7196,0.10858c-0.61223,0.02769 -1.2355,0.04434 -1.86999,0.05013c-0.63431,0.00543 -1.26337,0.00832 -1.88664,0.00832c-0.73457,0 -1.32725,-0.21427 -1.77806,-0.64282c-0.4508,-0.42836 -0.67612,-0.98775 -0.67612,-1.67798l0,-6.97867c0,-0.70127 0.22531,-1.26319 0.67612,-1.6863c0.4508,-0.42293 1.04349,-0.63449 1.77806,-0.63449c0.62327,0 1.25233,0.0029 1.88664,0.00832c0.63449,0.00579 1.25776,0.01954 1.86999,0.0418c0.61187,0.02244 1.18537,0.06135 1.7196,0.11691l-0.11691,1.85316l-4.42424,0c-0.27834,0 -0.48718,0.07257 -0.62616,0.21699c-0.13935,0.14478 -0.20866,0.35651 -0.20866,0.63449l0,1.80321l4.64141,0l0,1.80303l-4.64141,0l0,2.27066c0,0.27834 0.06931,0.48989 0.20866,0.63449c0.13899,0.14478 0.34783,0.21699 0.62616,0.21699l4.42424,0z" class="cls-2"/>
     <path stroke="null" id="svg_5" fill="#1e1e1e" d="m29.92993,20.48112c-0.35634,-0.51758 -0.9796,-0.81528 -1.86981,-0.89328l0,-0.10008c0.72335,-0.06678 1.24383,-0.31435 1.56107,-0.7429c0.31706,-0.42836 0.47578,-1.05453 0.47578,-1.87831s-0.13356,-1.40254 -0.40068,-1.83651c-0.26712,-0.43415 -0.69873,-0.73728 -1.29396,-0.90993c-0.59558,-0.17247 -1.39403,-0.25879 -2.3959,-0.25879c-0.65675,0 -1.22989,0.0029 -1.7196,0.00832c-0.48989,0.00579 -0.94323,0.01665 -1.36074,0.03348c-0.41732,0.01665 -0.84876,0.04741 -1.29377,0.09175l0,11.52c0.48953,0.0333 0.9682,0.06099 1.43566,0.08343c0.46763,0.02226 0.97671,0.03601 1.52777,0.0418c0.55088,0.00543 1.19913,0.00832 1.94491,0.00832c0.92387,0 1.67219,-0.08632 2.24569,-0.25879c0.57314,-0.17247 0.99589,-0.47578 1.2688,-0.90993c0.27272,-0.43397 0.409,-1.04602 0.409,-1.83651c0,-0.92369 -0.17808,-1.6445 -0.53423,-2.16208l0,0.00001zm-4.05704,-4.76664c0.45623,0 0.81239,0.03909 1.06864,0.11691c0.25589,0.078 0.43669,0.22531 0.54255,0.44248c0.10569,0.21699 0.15853,0.5317 0.15853,0.94323s-0.04995,0.71249 -0.15021,0.93508c-0.10026,0.22278 -0.27001,0.37298 -0.50926,0.45062c-0.23943,0.078 -0.565,0.1227 -0.97671,0.13356l-1.86981,0l0,-3.02188l1.73625,0l0,0zm1.87831,7.45463c-0.10587,0.23943 -0.29227,0.40357 -0.55939,0.49261c-0.26712,0.08922 -0.63431,0.13356 -1.10176,0.13356c-0.71339,0 -1.36454,-0.00272 -1.95342,-0.00832l0,-3.43106l1.90329,0c0.47849,0.01122 0.85401,0.06678 1.12692,0.16704c0.27255,0.10008 0.46456,0.27544 0.57604,0.52591c0.11112,0.25047 0.16685,0.61512 0.16685,1.09344c0,0.44537 -0.05284,0.78759 -0.15853,1.02684l0,0z" class="cls-2"/>
     <path stroke="null" id="svg_6" fill="#1e1e1e" d="m40.48154,21.92528c-0.08922,-0.40068 -0.22821,-0.72045 -0.41732,-0.96006c-0.18948,-0.23907 -0.39235,-0.41986 -0.60934,-0.54255c-0.21717,-0.12234 -0.42583,-0.20034 -0.62616,-0.23363l0,-0.08361c0.55649,-0.06678 0.99607,-0.22241 1.31893,-0.46745c0.32267,-0.24467 0.55359,-0.57025 0.69294,-0.97671c0.13899,-0.4061 0.20866,-0.87645 0.20866,-1.41068c0,-0.87917 -0.14767,-1.5665 -0.44248,-2.062c-0.29498,-0.49514 -0.75701,-0.84569 -1.38571,-1.05182c-0.62888,-0.20577 -1.44995,-0.30892 -2.4625,-0.30892c-0.69041,0 -1.2802,0.0029 -1.76991,0.00851c-0.48989,0.00561 -0.9407,0.01954 -1.35223,0.04162c-0.41189,0.02244 -0.85148,0.06135 -1.31893,0.11691l0,11.52l2.52095,0l0,-4.51509c0.50057,0.00507 1.10701,0.00724 1.81986,0.00724c0.34493,0 0.61494,0.07257 0.80967,0.21699c0.19473,0.14478 0.3424,0.40647 0.44248,0.7847l0.88496,3.50617l2.68799,0l-1.00186,-3.58959l0,0zm-2.16208,-3.48933c-0.10587,0.24486 -0.28956,0.41479 -0.55088,0.50926c-0.26168,0.09465 -0.61512,0.14188 -1.06014,0.14188l-1.86999,0l0,-3.33171c0.55215,-0.00489 1.1756,-0.00742 1.86999,-0.00742c0.44501,0 0.79845,0.04741 1.06014,0.14188c0.26133,0.09465 0.44501,0.26168 0.55088,0.50093c0.10569,0.23943 0.15871,0.58165 0.15871,1.02666s-0.05303,0.77384 -0.15871,1.01852z" class="cls-2"/>
     <path stroke="null" id="svg_7" fill="#1e1e1e" d="m49.51371,14.66266c-0.05574,-0.20034 -0.16433,-0.36158 -0.32557,-0.4841c-0.16143,-0.12234 -0.34801,-0.18369 -0.55921,-0.18369l-2.42087,0c-0.21156,0 -0.39814,0.06135 -0.55939,0.18369c-0.16143,0.12252 -0.27001,0.28376 -0.32557,0.4841l-3.35578,10.85221l2.62121,0l0.84496,-3.03853l3.96982,0l0.84496,3.03853l2.60456,0l-3.33913,-10.85221l0,0zm-3.54616,5.89356l0.97508,-3.50598c0.05556,-0.18911 0.10279,-0.38113 0.14188,-0.57604c0.03891,-0.19455 0.0751,-0.38113 0.10858,-0.55939l0.4508,0c0.0333,0.17826 0.07221,0.36484 0.11673,0.55939c0.04434,0.19491 0.08904,0.38692 0.13374,0.57604l0.9749,3.50598l-2.90172,0z" class="cls-2"/>
    </g>
    <g stroke="null" id="svg_8">
     <path stroke="null" id="svg_9" fill="#1e1e1e" d="m7.45276,35.41028c0,0.56283 -0.13193,1.05869 -0.39524,1.48742c-0.26368,0.42891 -0.62544,0.76388 -1.08565,1.00512c-0.46021,0.24124 -0.98956,0.36195 -1.58804,0.36195s-1.11009,-0.11636 -1.58804,-0.34855c-0.47795,-0.23219 -0.87772,-0.56717 -1.19931,-1.00512l0.48229,-0.4689c0.24124,0.36629 0.56301,0.65222 0.96495,0.85763c0.40212,0.20559 0.84424,0.3082 1.32671,0.3082s0.88659,-0.09157 1.23966,-0.27472c0.3529,-0.18296 0.62761,-0.4423 0.82415,-0.77728c0.19653,-0.33498 0.29481,-0.72136 0.29481,-1.15931s-0.09827,-0.84858 -0.29481,-1.17922c-0.19653,-0.33046 -0.4736,-0.58961 -0.83085,-0.77728c-0.35742,-0.18767 -0.7818,-0.28141 -1.27314,-0.28141c-0.14297,0 -0.29046,0.00905 -0.4423,0.02678c-0.15202,0.01791 -0.3082,0.04922 -0.4689,0.09374l0,-0.44212l2.89339,-3.21643l-4.34081,0l0,-0.62978l5.37399,0l0,0.45551l-2.91547,3.19851c0.0751,-0.00597 0.15346,-0.00887 0.23508,-0.00887c0.52717,0 1.0006,0.11854 1.42064,0.35507c0.41986,0.2369 0.75249,0.56518 0.99843,0.98504c0.24558,0.42004 0.36846,0.89781 0.36846,1.43403l0,0z" class="cls-2"/>
     <path stroke="null" id="svg_10" fill="#1e1e1e" d="m14.69619,33.63458c-0.27272,-0.45117 -0.63449,-0.81293 -1.08565,-1.08565c-0.45117,-0.27237 -0.94938,-0.40864 -1.49411,-0.40864c-0.23255,0 -0.45569,0.02678 -0.67014,0.08035c-0.2036,0.05085 -0.38909,0.12198 -0.55613,0.21301l2.67351,-3.44264l-0.84424,0l-3.06894,4.04709c-0.51831,0.68824 -0.77728,1.40724 -0.77728,2.15774c0,0.57187 0.13845,1.09 0.41551,1.55455c0.27671,0.46456 0.64987,0.8332 1.11895,1.10556c0.46908,0.27272 0.98938,0.40882 1.56125,0.40882s1.10773,-0.13845 1.58134,-0.41551c0.47342,-0.27689 0.85093,-0.65204 1.13235,-1.12565c0.28159,-0.47342 0.42221,-0.99173 0.42221,-1.55455s-0.13627,-1.0833 -0.40864,-1.53447l0,0zm-0.62996,2.76056c-0.21898,0.371 -0.51161,0.67014 -0.87772,0.89799c-0.36647,0.22785 -0.76859,0.34167 -1.20618,0.34167s-0.85093,-0.10931 -1.21288,-0.32828c-0.36176,-0.21879 -0.64987,-0.51595 -0.86433,-0.89111c-0.21445,-0.37534 -0.32159,-0.79085 -0.32159,-1.24636s0.10714,-0.87989 0.32159,-1.24636c0.21445,-0.36629 0.50256,-0.65874 0.86433,-0.8779c0.36195,-0.21879 0.76606,-0.32828 1.21288,-0.32828s0.83971,0.10949 1.20618,0.32828c0.36611,0.21916 0.65874,0.51595 0.87772,0.89129c0.21879,0.37516 0.32828,0.78633 0.32828,1.23297s-0.10949,0.85546 -0.32828,1.22609l0,0z" class="cls-2"/>
     <path stroke="null" id="svg_11" fill="#1e1e1e" d="m23.31954,31.0615c-0.32159,-0.70127 -0.75701,-1.244 -1.30663,-1.62839c-0.54943,-0.38402 -1.16384,-0.57622 -1.84266,-0.57622s-1.26645,0.19219 -1.81588,0.57622c-0.54943,0.38439 -0.98504,0.92477 -1.30663,1.6217c-0.32159,0.69674 -0.48247,1.52777 -0.48247,2.49254s0.16288,1.7871 0.48917,2.49272c0.32611,0.7058 0.76606,1.25305 1.32002,1.6416c0.55396,0.38873 1.16601,0.58309 1.83597,0.58309s1.26428,-0.19201 1.80918,-0.5764c0.54491,-0.38402 0.97834,-0.92911 1.29993,-1.63491c0.32177,-0.70561 0.48247,-1.53646 0.48247,-2.49272s-0.1607,-1.79778 -0.48247,-2.49923l0,0zm-0.6097,4.67036c-0.25463,0.6077 -0.60101,1.06991 -1.0386,1.38697c-0.43777,0.31724 -0.93364,0.47578 -1.4876,0.47578s-1.04982,-0.15853 -1.48742,-0.47578c-0.43795,-0.31706 -0.78397,-0.77927 -1.0386,-1.38697c-0.25481,-0.60752 -0.38204,-1.33558 -0.38204,-2.18452s0.12723,-1.57229 0.38204,-2.17095c0.25463,-0.59848 0.60065,-1.05634 1.0386,-1.37358c0.43759,-0.31706 0.93346,-0.47578 1.48742,-0.47578s1.04982,0.15871 1.4876,0.47578c0.43759,0.31724 0.78397,0.77728 1.0386,1.38028c0.25463,0.603 0.38204,1.32454 0.38204,2.16425s-0.12741,1.577 -0.38204,2.18452l0,0z" class="cls-2"/>
    </g>
   </g>
   <path stroke="null" id="svg_12" fill="url(#Adsız_degrade_344)" d="m56.81469,38.2644l-2.61614,0s-3.31108,-10.82995 -3.31108,-10.82995l2.59859,0.00036l3.32863,10.82977l0,-0.00018l0,0zm-11.40725,-37.19369l-2.59859,-0.00036l3.31108,10.82995l2.61614,0s-3.32863,-10.82977 -3.32863,-10.82977l0,0.00018l0,0zm11.45992,-0.00018l-2.61614,0l7.02102,22.96471l1.31567,-4.25377l-5.72055,-18.71095zm-9.11161,26.3641l-2.59859,-0.00036l3.31108,10.82995l2.61614,0s-3.32863,-10.82977 -3.32863,-10.82977l0,0.00018l0,0zm-8.07862,-26.3641l-2.59859,-0.00036l3.31108,10.82995l2.61614,0s-3.32863,-10.82977 -3.32863,-10.82977l0,0.00018l0,0zm2.3483,26.3641l-2.59859,-0.00036l3.31108,10.82995l2.61614,0s-3.32863,-10.82977 -3.32863,-10.82977l0,0.00018l0,0zm-8.07862,-26.3641l-2.59859,-0.00036l3.31108,10.82995l2.61614,0s-3.32863,-10.82977 -3.32863,-10.82977l0,0.00018l0,0zm17.2087,0l-2.61614,0l9.86048,32.25223l1.31567,-4.25358l0,0s-8.56001,-27.99865 -8.56001,-27.99865l0,0z" class="cls-1"/>
  </g>
 </g>
</svg>
  );

  const fullLogo = (
<svg width="64" height="39" xmlns="http://www.w3.org/2000/svg">
 <defs>
  <linearGradient y2="0.5" x2="1.00001" y1="0.5" x1="-0.00001" id="Adsız_degrade_344">
   <stop stop-color="#00ffe0" offset="0"/>
   <stop stop-color="#09f" offset="0.55"/>
   <stop stop-color="#167aff" offset="0.65"/>
   <stop stop-color="#4e2eff" offset="0.87"/>
   <stop stop-color="#7000ff" offset="1"/>
  </linearGradient>
 </defs>
 <g>
  <title>Layer 1</title>
  <g stroke="null" id="svg_13">
   <g stroke="null" id="svg_1">
    <g stroke="null" id="svg_2">
     <polygon stroke="null" id="svg_3" fill="#1e1e1e" points="10.195943504571915,23.611576080322266 10.195943504571915,25.51486587524414 1.6811658442020416,25.51486587524414 1.6811658442020416,23.611576080322266 7.245550781488419,15.898164749145508 1.8147243559360504,15.898164749145508 1.8147243559360504,13.99487590789795 10.062570244073868,13.99487590789795 10.062570244073868,15.898164749145508 4.514664322137833,23.611576080322266 10.195943504571915,23.611576080322266 " class="cls-2"/>
     <path stroke="null" id="svg_4" fill="#1e1e1e" d="m19.71257,23.5448l0.11691,1.85316c-0.53423,0.0447 -1.10773,0.08089 -1.7196,0.10858c-0.61223,0.02769 -1.2355,0.04434 -1.86999,0.05013c-0.63431,0.00543 -1.26337,0.00832 -1.88664,0.00832c-0.73457,0 -1.32725,-0.21427 -1.77806,-0.64282c-0.4508,-0.42836 -0.67612,-0.98775 -0.67612,-1.67798l0,-6.97867c0,-0.70127 0.22531,-1.26319 0.67612,-1.6863c0.4508,-0.42293 1.04349,-0.63449 1.77806,-0.63449c0.62327,0 1.25233,0.0029 1.88664,0.00832c0.63449,0.00579 1.25776,0.01954 1.86999,0.0418c0.61187,0.02244 1.18537,0.06135 1.7196,0.11691l-0.11691,1.85316l-4.42424,0c-0.27834,0 -0.48718,0.07257 -0.62616,0.21699c-0.13935,0.14478 -0.20866,0.35651 -0.20866,0.63449l0,1.80321l4.64141,0l0,1.80303l-4.64141,0l0,2.27066c0,0.27834 0.06931,0.48989 0.20866,0.63449c0.13899,0.14478 0.34783,0.21699 0.62616,0.21699l4.42424,0z" class="cls-2"/>
     <path stroke="null" id="svg_5" fill="#1e1e1e" d="m29.92993,20.48112c-0.35634,-0.51758 -0.9796,-0.81528 -1.86981,-0.89328l0,-0.10008c0.72335,-0.06678 1.24383,-0.31435 1.56107,-0.7429c0.31706,-0.42836 0.47578,-1.05453 0.47578,-1.87831s-0.13356,-1.40254 -0.40068,-1.83651c-0.26712,-0.43415 -0.69873,-0.73728 -1.29396,-0.90993c-0.59558,-0.17247 -1.39403,-0.25879 -2.3959,-0.25879c-0.65675,0 -1.22989,0.0029 -1.7196,0.00832c-0.48989,0.00579 -0.94323,0.01665 -1.36074,0.03348c-0.41732,0.01665 -0.84876,0.04741 -1.29377,0.09175l0,11.52c0.48953,0.0333 0.9682,0.06099 1.43566,0.08343c0.46763,0.02226 0.97671,0.03601 1.52777,0.0418c0.55088,0.00543 1.19913,0.00832 1.94491,0.00832c0.92387,0 1.67219,-0.08632 2.24569,-0.25879c0.57314,-0.17247 0.99589,-0.47578 1.2688,-0.90993c0.27272,-0.43397 0.409,-1.04602 0.409,-1.83651c0,-0.92369 -0.17808,-1.6445 -0.53423,-2.16208l0,0.00001zm-4.05704,-4.76664c0.45623,0 0.81239,0.03909 1.06864,0.11691c0.25589,0.078 0.43669,0.22531 0.54255,0.44248c0.10569,0.21699 0.15853,0.5317 0.15853,0.94323s-0.04995,0.71249 -0.15021,0.93508c-0.10026,0.22278 -0.27001,0.37298 -0.50926,0.45062c-0.23943,0.078 -0.565,0.1227 -0.97671,0.13356l-1.86981,0l0,-3.02188l1.73625,0l0,0zm1.87831,7.45463c-0.10587,0.23943 -0.29227,0.40357 -0.55939,0.49261c-0.26712,0.08922 -0.63431,0.13356 -1.10176,0.13356c-0.71339,0 -1.36454,-0.00272 -1.95342,-0.00832l0,-3.43106l1.90329,0c0.47849,0.01122 0.85401,0.06678 1.12692,0.16704c0.27255,0.10008 0.46456,0.27544 0.57604,0.52591c0.11112,0.25047 0.16685,0.61512 0.16685,1.09344c0,0.44537 -0.05284,0.78759 -0.15853,1.02684l0,0z" class="cls-2"/>
     <path stroke="null" id="svg_6" fill="#1e1e1e" d="m40.48154,21.92528c-0.08922,-0.40068 -0.22821,-0.72045 -0.41732,-0.96006c-0.18948,-0.23907 -0.39235,-0.41986 -0.60934,-0.54255c-0.21717,-0.12234 -0.42583,-0.20034 -0.62616,-0.23363l0,-0.08361c0.55649,-0.06678 0.99607,-0.22241 1.31893,-0.46745c0.32267,-0.24467 0.55359,-0.57025 0.69294,-0.97671c0.13899,-0.4061 0.20866,-0.87645 0.20866,-1.41068c0,-0.87917 -0.14767,-1.5665 -0.44248,-2.062c-0.29498,-0.49514 -0.75701,-0.84569 -1.38571,-1.05182c-0.62888,-0.20577 -1.44995,-0.30892 -2.4625,-0.30892c-0.69041,0 -1.2802,0.0029 -1.76991,0.00851c-0.48989,0.00561 -0.9407,0.01954 -1.35223,0.04162c-0.41189,0.02244 -0.85148,0.06135 -1.31893,0.11691l0,11.52l2.52095,0l0,-4.51509c0.50057,0.00507 1.10701,0.00724 1.81986,0.00724c0.34493,0 0.61494,0.07257 0.80967,0.21699c0.19473,0.14478 0.3424,0.40647 0.44248,0.7847l0.88496,3.50617l2.68799,0l-1.00186,-3.58959l0,0zm-2.16208,-3.48933c-0.10587,0.24486 -0.28956,0.41479 -0.55088,0.50926c-0.26168,0.09465 -0.61512,0.14188 -1.06014,0.14188l-1.86999,0l0,-3.33171c0.55215,-0.00489 1.1756,-0.00742 1.86999,-0.00742c0.44501,0 0.79845,0.04741 1.06014,0.14188c0.26133,0.09465 0.44501,0.26168 0.55088,0.50093c0.10569,0.23943 0.15871,0.58165 0.15871,1.02666s-0.05303,0.77384 -0.15871,1.01852z" class="cls-2"/>
     <path stroke="null" id="svg_7" fill="#1e1e1e" d="m49.51371,14.66266c-0.05574,-0.20034 -0.16433,-0.36158 -0.32557,-0.4841c-0.16143,-0.12234 -0.34801,-0.18369 -0.55921,-0.18369l-2.42087,0c-0.21156,0 -0.39814,0.06135 -0.55939,0.18369c-0.16143,0.12252 -0.27001,0.28376 -0.32557,0.4841l-3.35578,10.85221l2.62121,0l0.84496,-3.03853l3.96982,0l0.84496,3.03853l2.60456,0l-3.33913,-10.85221l0,0zm-3.54616,5.89356l0.97508,-3.50598c0.05556,-0.18911 0.10279,-0.38113 0.14188,-0.57604c0.03891,-0.19455 0.0751,-0.38113 0.10858,-0.55939l0.4508,0c0.0333,0.17826 0.07221,0.36484 0.11673,0.55939c0.04434,0.19491 0.08904,0.38692 0.13374,0.57604l0.9749,3.50598l-2.90172,0z" class="cls-2"/>
    </g>
    <g stroke="null" id="svg_8">
     <path stroke="null" id="svg_9" fill="#1e1e1e" d="m7.45276,35.41028c0,0.56283 -0.13193,1.05869 -0.39524,1.48742c-0.26368,0.42891 -0.62544,0.76388 -1.08565,1.00512c-0.46021,0.24124 -0.98956,0.36195 -1.58804,0.36195s-1.11009,-0.11636 -1.58804,-0.34855c-0.47795,-0.23219 -0.87772,-0.56717 -1.19931,-1.00512l0.48229,-0.4689c0.24124,0.36629 0.56301,0.65222 0.96495,0.85763c0.40212,0.20559 0.84424,0.3082 1.32671,0.3082s0.88659,-0.09157 1.23966,-0.27472c0.3529,-0.18296 0.62761,-0.4423 0.82415,-0.77728c0.19653,-0.33498 0.29481,-0.72136 0.29481,-1.15931s-0.09827,-0.84858 -0.29481,-1.17922c-0.19653,-0.33046 -0.4736,-0.58961 -0.83085,-0.77728c-0.35742,-0.18767 -0.7818,-0.28141 -1.27314,-0.28141c-0.14297,0 -0.29046,0.00905 -0.4423,0.02678c-0.15202,0.01791 -0.3082,0.04922 -0.4689,0.09374l0,-0.44212l2.89339,-3.21643l-4.34081,0l0,-0.62978l5.37399,0l0,0.45551l-2.91547,3.19851c0.0751,-0.00597 0.15346,-0.00887 0.23508,-0.00887c0.52717,0 1.0006,0.11854 1.42064,0.35507c0.41986,0.2369 0.75249,0.56518 0.99843,0.98504c0.24558,0.42004 0.36846,0.89781 0.36846,1.43403l0,0z" class="cls-2"/>
     <path stroke="null" id="svg_10" fill="#1e1e1e" d="m14.69619,33.63458c-0.27272,-0.45117 -0.63449,-0.81293 -1.08565,-1.08565c-0.45117,-0.27237 -0.94938,-0.40864 -1.49411,-0.40864c-0.23255,0 -0.45569,0.02678 -0.67014,0.08035c-0.2036,0.05085 -0.38909,0.12198 -0.55613,0.21301l2.67351,-3.44264l-0.84424,0l-3.06894,4.04709c-0.51831,0.68824 -0.77728,1.40724 -0.77728,2.15774c0,0.57187 0.13845,1.09 0.41551,1.55455c0.27671,0.46456 0.64987,0.8332 1.11895,1.10556c0.46908,0.27272 0.98938,0.40882 1.56125,0.40882s1.10773,-0.13845 1.58134,-0.41551c0.47342,-0.27689 0.85093,-0.65204 1.13235,-1.12565c0.28159,-0.47342 0.42221,-0.99173 0.42221,-1.55455s-0.13627,-1.0833 -0.40864,-1.53447l0,0zm-0.62996,2.76056c-0.21898,0.371 -0.51161,0.67014 -0.87772,0.89799c-0.36647,0.22785 -0.76859,0.34167 -1.20618,0.34167s-0.85093,-0.10931 -1.21288,-0.32828c-0.36176,-0.21879 -0.64987,-0.51595 -0.86433,-0.89111c-0.21445,-0.37534 -0.32159,-0.79085 -0.32159,-1.24636s0.10714,-0.87989 0.32159,-1.24636c0.21445,-0.36629 0.50256,-0.65874 0.86433,-0.8779c0.36195,-0.21879 0.76606,-0.32828 1.21288,-0.32828s0.83971,0.10949 1.20618,0.32828c0.36611,0.21916 0.65874,0.51595 0.87772,0.89129c0.21879,0.37516 0.32828,0.78633 0.32828,1.23297s-0.10949,0.85546 -0.32828,1.22609l0,0z" class="cls-2"/>
     <path stroke="null" id="svg_11" fill="#1e1e1e" d="m23.31954,31.0615c-0.32159,-0.70127 -0.75701,-1.244 -1.30663,-1.62839c-0.54943,-0.38402 -1.16384,-0.57622 -1.84266,-0.57622s-1.26645,0.19219 -1.81588,0.57622c-0.54943,0.38439 -0.98504,0.92477 -1.30663,1.6217c-0.32159,0.69674 -0.48247,1.52777 -0.48247,2.49254s0.16288,1.7871 0.48917,2.49272c0.32611,0.7058 0.76606,1.25305 1.32002,1.6416c0.55396,0.38873 1.16601,0.58309 1.83597,0.58309s1.26428,-0.19201 1.80918,-0.5764c0.54491,-0.38402 0.97834,-0.92911 1.29993,-1.63491c0.32177,-0.70561 0.48247,-1.53646 0.48247,-2.49272s-0.1607,-1.79778 -0.48247,-2.49923l0,0zm-0.6097,4.67036c-0.25463,0.6077 -0.60101,1.06991 -1.0386,1.38697c-0.43777,0.31724 -0.93364,0.47578 -1.4876,0.47578s-1.04982,-0.15853 -1.48742,-0.47578c-0.43795,-0.31706 -0.78397,-0.77927 -1.0386,-1.38697c-0.25481,-0.60752 -0.38204,-1.33558 -0.38204,-2.18452s0.12723,-1.57229 0.38204,-2.17095c0.25463,-0.59848 0.60065,-1.05634 1.0386,-1.37358c0.43759,-0.31706 0.93346,-0.47578 1.48742,-0.47578s1.04982,0.15871 1.4876,0.47578c0.43759,0.31724 0.78397,0.77728 1.0386,1.38028c0.25463,0.603 0.38204,1.32454 0.38204,2.16425s-0.12741,1.577 -0.38204,2.18452l0,0z" class="cls-2"/>
    </g>
   </g>
   <path stroke="null" id="svg_12" fill="url(#Adsız_degrade_344)" d="m56.81469,38.2644l-2.61614,0s-3.31108,-10.82995 -3.31108,-10.82995l2.59859,0.00036l3.32863,10.82977l0,-0.00018l0,0zm-11.40725,-37.19369l-2.59859,-0.00036l3.31108,10.82995l2.61614,0s-3.32863,-10.82977 -3.32863,-10.82977l0,0.00018l0,0zm11.45992,-0.00018l-2.61614,0l7.02102,22.96471l1.31567,-4.25377l-5.72055,-18.71095zm-9.11161,26.3641l-2.59859,-0.00036l3.31108,10.82995l2.61614,0s-3.32863,-10.82977 -3.32863,-10.82977l0,0.00018l0,0zm-8.07862,-26.3641l-2.59859,-0.00036l3.31108,10.82995l2.61614,0s-3.32863,-10.82977 -3.32863,-10.82977l0,0.00018l0,0zm2.3483,26.3641l-2.59859,-0.00036l3.31108,10.82995l2.61614,0s-3.32863,-10.82977 -3.32863,-10.82977l0,0.00018l0,0zm-8.07862,-26.3641l-2.59859,-0.00036l3.31108,10.82995l2.61614,0s-3.32863,-10.82977 -3.32863,-10.82977l0,0.00018l0,0zm17.2087,0l-2.61614,0l9.86048,32.25223l1.31567,-4.25358l0,0s-8.56001,-27.99865 -8.56001,-27.99865l0,0z" class="cls-1"/>
  </g>
 </g>
</svg>
  );

  return (
    <Link
      component={RouterLink}
      href="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: single ? 78 : 128,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        {single ? singleLogo : fullLogo}
      </Box>
    </Link>
  );
}

Logo.propTypes = {
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(Logo);
