import { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

function Logo({ single = false, sx }) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const singleLogo = (
    <svg width="128" height="78" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient y2="0.5" x2="1.00001" y1="0.5" x1="-0.00001" id="Adsız_degrade_344">
      <stop stop-color="#00ffe0" offset="0"/>
      <stop stop-color="#09f" offset="0.55"/>
      <stop stop-color="#167aff" offset="0.65"/>
      <stop stop-color="#4e2eff" offset="0.87"/>
      <stop stop-color="#7000ff" offset="1"/>
      </linearGradient>
    </defs>
    <g>
      <title>Layer 1</title>
      <g stroke="null" id="svg_13">
      <g stroke="null" id="svg_1">
        <g stroke="null" id="svg_2">
        <polygon stroke="null" id="svg_3" fill="#1e1e1e" points="18.84513521194458,47.23628616333008 18.84513521194458,51.11211395263672 1.5057785362005234,51.11211395263672 1.5057785362005234,47.23628616333008 12.837000370025635,31.52882194519043 1.7777546048164368,31.52882194519043 1.7777546048164368,27.652996063232422 18.57353639602661,27.652996063232422 18.57353639602661,31.52882194519043 7.275867938995361,47.23628616333008 18.84513521194458,47.23628616333008 " class="cls-2"/>
        <path stroke="null" id="svg_4" fill="#1e1e1e" d="m38.22464,47.10031l0.23807,3.77375c-1.0879,0.09103 -2.25577,0.16473 -3.50177,0.22112c-1.24674,0.05639 -2.51595,0.09029 -3.80802,0.10208c-1.2917,0.01106 -2.57271,0.01695 -3.84192,0.01695c-1.49586,0 -2.7028,-0.43634 -3.62081,-1.30902c-0.91801,-0.87231 -1.37683,-2.01144 -1.37683,-3.41701l0,-14.21125c0,-1.42805 0.45882,-2.57234 1.37683,-3.43396c0.91801,-0.86125 2.12494,-1.29207 3.62081,-1.29207c1.26922,0 2.55023,0.0059 3.84192,0.01695c1.29207,0.01179 2.56128,0.0398 3.80802,0.08513c1.246,0.0457 2.41387,0.12493 3.50177,0.23807l-0.23807,3.77375l-9.00945,0c-0.5668,0 -0.99208,0.14778 -1.27511,0.44187c-0.28377,0.29482 -0.42491,0.726 -0.42491,1.29207l0,3.67203l9.45169,0l0,3.67166l-9.45169,0l0,4.62394c0,0.5668 0.14115,0.99761 0.42491,1.29207c0.28303,0.29482 0.70831,0.44187 1.27511,0.44187l9.00945,0z" class="cls-2"/>
        <path stroke="null" id="svg_5" fill="#1e1e1e" d="m59.0311,40.86147c-0.72564,-1.054 -1.99485,-1.66023 -3.80765,-1.81906l0,-0.2038c1.47301,-0.13599 2.53291,-0.64014 3.17894,-1.51282c0.64566,-0.87231 0.96887,-2.14742 0.96887,-3.82497s-0.27198,-2.85611 -0.81593,-3.73984c-0.54395,-0.8841 -1.42289,-1.50139 -2.63499,-1.85297c-1.21283,-0.35121 -2.83879,-0.527 -4.87897,-0.527c-1.33739,0 -2.50453,0.0059 -3.50177,0.01695c-0.99761,0.01179 -1.92078,0.0339 -2.77098,0.06818c-0.84983,0.0339 -1.72841,0.09655 -2.63462,0.18684l0,23.45914c0.99687,0.06781 1.97163,0.12419 2.92355,0.16989c0.95228,0.04533 1.98896,0.07334 3.11113,0.08513c1.1218,0.01106 2.44188,0.01695 3.96059,0.01695c1.88135,0 3.40522,-0.17579 4.57309,-0.527c1.16713,-0.35121 2.02802,-0.96887 2.58376,-1.85297c0.55537,-0.88373 0.83288,-2.1301 0.83288,-3.73984c0,-1.88098 -0.36263,-3.34883 -1.0879,-4.40283zm-8.2617,-9.70671c0.92906,0 1.65433,0.0796 2.17617,0.23807c0.5211,0.15884 0.88926,0.45882 1.10485,0.90106c0.21522,0.44187 0.32283,1.08274 0.32283,1.92078s-0.10171,1.4509 -0.30588,1.90419c-0.20417,0.45366 -0.54985,0.75954 -1.03704,0.91764c-0.48757,0.15884 -1.15055,0.24986 -1.98896,0.27198l-3.80765,0l0,-6.15371l3.53568,0zm3.82497,15.18048c-0.21559,0.48757 -0.59518,0.82182 -1.13913,1.00314c-0.54395,0.18169 -1.2917,0.27198 -2.24361,0.27198c-1.45274,0 -2.77872,-0.00553 -3.97791,-0.01695l0,-6.98696l3.87583,0c0.97439,0.02285 1.73909,0.13599 2.29484,0.34015c0.55501,0.2038 0.94602,0.5609 1.17303,1.07095c0.22628,0.51005 0.33978,1.25263 0.33978,2.22666c0,0.90695 -0.10761,1.60384 -0.32283,2.09104z" class="cls-2"/>
        <path stroke="null" id="svg_6" fill="#1e1e1e" d="m80.51824,43.80234c-0.18169,-0.81593 -0.46472,-1.46712 -0.84983,-1.95505c-0.38585,-0.48683 -0.79897,-0.85499 -1.24084,-1.10485c-0.44224,-0.24913 -0.86715,-0.40796 -1.27511,-0.47577l0,-0.17026c1.13323,-0.13599 2.02839,-0.45292 2.68585,-0.95191c0.65709,-0.49825 1.12733,-1.16124 1.4111,-1.98896c0.28303,-0.82698 0.42491,-1.78479 0.42491,-2.87269c0,-1.79032 -0.30072,-3.18999 -0.90106,-4.19903c-0.6007,-1.0083 -1.54156,-1.72214 -2.82183,-2.1419c-1.28064,-0.41902 -2.95266,-0.62908 -5.01459,-0.62908c-1.40594,0 -2.60698,0.0059 -3.60422,0.01732c-0.99761,0.01142 -1.91562,0.0398 -2.75366,0.08476c-0.83877,0.0457 -1.73393,0.12493 -2.68585,0.23807l0,23.45914l5.13362,0l0,-9.19445c1.01935,0.01032 2.2543,0.01474 3.70594,0.01474c0.70242,0 1.25226,0.14778 1.6488,0.44187c0.39654,0.29482 0.69726,0.82772 0.90106,1.59795l1.80211,7.1399l5.47377,0l-2.04018,-7.30979zm-4.40283,-7.10562c-0.21559,0.49862 -0.58965,0.84467 -1.1218,1.03704c-0.53289,0.19274 -1.25263,0.28893 -2.15885,0.28893l-3.80802,0l0,-6.78464c1.12438,-0.00995 2.39397,-0.01511 3.80802,-0.01511c0.90622,0 1.62595,0.09655 2.15885,0.28893c0.53216,0.19274 0.90622,0.53289 1.1218,1.02009c0.21522,0.48757 0.3232,1.18446 0.3232,2.09067s-0.10798,1.57583 -0.3232,2.07409z" class="cls-2"/>
        <path stroke="null" id="svg_7" fill="#1e1e1e" d="m98.91119,29.01286c-0.11351,-0.40796 -0.33463,-0.73632 -0.66299,-0.98582c-0.32873,-0.24913 -0.70868,-0.37406 -1.13876,-0.37406l-4.92982,0c-0.43081,0 -0.81077,0.12493 -1.13913,0.37406c-0.32873,0.24949 -0.54985,0.57785 -0.66299,0.98582l-6.83365,22.09926l5.33779,0l1.72067,-6.18762l8.08407,0l1.72067,6.18762l5.30388,0l-6.79975,-22.09926zm-7.22134,12.00154l1.98564,-7.13953c0.11314,-0.38511 0.20932,-0.77612 0.28893,-1.17303c0.07923,-0.39617 0.15294,-0.77612 0.22112,-1.13913l0.91801,0c0.06781,0.363 0.14704,0.74296 0.2377,1.13913c0.09029,0.39691 0.18132,0.78792 0.27234,1.17303l1.98527,7.13953l-5.90901,0z" class="cls-2"/>
        </g>
        <g stroke="null" id="svg_8">
        <path stroke="null" id="svg_9" fill="#1e1e1e" d="m13.25895,71.26297c0,1.14613 -0.26866,2.1559 -0.80487,3.02895c-0.53695,0.87342 -1.27364,1.55556 -2.21081,2.04682c-0.93717,0.49125 -2.01512,0.73706 -3.23385,0.73706s-2.26056,-0.23696 -3.23385,-0.70979c-0.97329,-0.47282 -1.78737,-1.15497 -2.44225,-2.04682l0.98213,-0.95486c0.49125,0.7459 1.1465,1.32818 1.965,1.74646c0.81887,0.41865 1.71919,0.62761 2.70169,0.62761s1.80543,-0.18648 2.52443,-0.55943c0.71863,-0.37258 1.27806,-0.90069 1.67829,-1.58284c0.40022,-0.68215 0.60034,-1.46896 0.60034,-2.3608s-0.20011,-1.72804 -0.60034,-2.40134c-0.40022,-0.67294 -0.96444,-1.20067 -1.69192,-1.58284c-0.72785,-0.38217 -1.59205,-0.57306 -2.59261,-0.57306c-0.29114,0 -0.59149,0.01843 -0.90069,0.05454c-0.30957,0.03648 -0.62761,0.10024 -0.95486,0.1909l0,-0.90032l5.89206,-6.54988l-8.83956,0l0,-1.28248l10.9435,0l0,0.92759l-5.93702,6.5134c0.15294,-0.01216 0.31251,-0.01806 0.47872,-0.01806c1.07353,0 2.0376,0.24139 2.89296,0.72306c0.85499,0.48241 1.53235,1.15092 2.03318,2.00591c0.5001,0.85536 0.75033,1.82828 0.75033,2.92023z" class="cls-2"/>
        <path stroke="null" id="svg_10" fill="#1e1e1e" d="m28.00936,67.64696c-0.55537,-0.91875 -1.29207,-1.65544 -2.21081,-2.21081c-0.91875,-0.55464 -1.93331,-0.83214 -3.04258,-0.83214c-0.47356,0 -0.92796,0.05454 -1.36467,0.16363c-0.4146,0.10356 -0.79234,0.24839 -1.13249,0.43376l5.44429,-7.01054l-1.71919,0l-6.24953,8.24143c-1.05547,1.40152 -1.58284,2.86569 -1.58284,4.39398c0,1.16455 0.28193,2.21966 0.84614,3.16567c0.56348,0.94602 1.32339,1.69671 2.27862,2.25135c0.95523,0.55537 2.01475,0.83251 3.17931,0.83251s2.25577,-0.28193 3.22021,-0.84614c0.96407,-0.56385 1.73283,-1.32781 2.30589,-2.29226c0.57343,-0.96407 0.85978,-2.01954 0.85978,-3.16567s-0.2775,-2.20602 -0.83214,-3.12476zm-1.28285,5.62155c-0.44592,0.75549 -1.04183,1.36467 -1.78737,1.82865c-0.74627,0.46398 -1.56515,0.69578 -2.45625,0.69578s-1.73283,-0.22259 -2.46989,-0.66851c-0.73669,-0.44555 -1.32339,-1.05068 -1.7601,-1.81464c-0.43671,-0.76433 -0.65488,-1.61048 -0.65488,-2.53807s0.21817,-1.79179 0.65488,-2.53807c0.43671,-0.7459 1.02341,-1.34145 1.7601,-1.78774c0.73706,-0.44555 1.55999,-0.66851 2.46989,-0.66851s1.70998,0.22296 2.45625,0.66851c0.74554,0.44629 1.34145,1.05068 1.78737,1.81501c0.44555,0.76396 0.66851,1.60126 0.66851,2.51079s-0.22296,1.74204 -0.66851,2.49679z" class="cls-2"/>
        <path stroke="null" id="svg_11" fill="#1e1e1e" d="m45.56981,62.4072c-0.65488,-1.42805 -1.54156,-2.53327 -2.66079,-3.31603c-1.11886,-0.78202 -2.37002,-1.1734 -3.75237,-1.1734s-2.57897,0.39138 -3.69783,1.1734c-1.11886,0.78276 -2.00591,1.88319 -2.66079,3.3024c-0.65488,1.41884 -0.9825,3.11113 -0.9825,5.07576s0.33168,3.63923 0.99614,5.07613c0.66409,1.43727 1.55999,2.5517 2.68806,3.34293c1.12807,0.7916 2.37444,1.1874 3.73874,1.1874s2.57455,-0.39101 3.68419,-1.17377c1.10964,-0.78202 1.99227,-1.89203 2.64715,-3.3293c0.65525,-1.4369 0.9825,-3.12882 0.9825,-5.07613s-0.32725,-3.66098 -0.9825,-5.0894zm-1.24158,9.51065c-0.51852,1.23752 -1.22389,2.17875 -2.11499,2.82441c-0.89147,0.64603 -1.90125,0.96887 -3.02932,0.96887s-2.13784,-0.32283 -3.02895,-0.96887c-0.89184,-0.64566 -1.59647,-1.58689 -2.11499,-2.82441c-0.51889,-1.23715 -0.77797,-2.71975 -0.77797,-4.44852s0.25908,-3.20179 0.77797,-4.42088c0.51852,-1.21873 1.22315,-2.15111 2.11499,-2.79714c0.89111,-0.64566 1.90088,-0.96887 3.02895,-0.96887s2.13784,0.3232 3.02932,0.96887c0.89111,0.64603 1.59647,1.58284 2.11499,2.81078c0.51852,1.22794 0.77797,2.69727 0.77797,4.40725s-0.25945,3.21137 -0.77797,4.44852z" class="cls-2"/>
        </g>
      </g>
      <path stroke="null" id="svg_12" fill="url(#Adsız_degrade_344)" d="m113.7788,77.07506l-5.32747,0s-6.74262,-22.05393 -6.74262,-22.05393l5.29172,0.00074l6.77837,22.05357l0,-0.00037zm-23.22954,-75.74063l-5.29172,-0.00074l6.74262,22.05393l5.32747,0s-6.77837,-22.05357 -6.77837,-22.05357l0,0.00037zm23.33679,-0.00037l-5.32747,0l14.29749,46.76497l2.67921,-8.6623l-11.64923,-38.10267zm-18.55474,53.68743l-5.29172,-0.00074l6.74262,22.05393l5.32747,0s-6.77837,-22.05357 -6.77837,-22.05357l0,0.00037zm-16.45117,-53.68743l-5.29172,-0.00074l6.74262,22.05393l5.32747,0s-6.77837,-22.05357 -6.77837,-22.05357l0,0.00037zm4.78204,53.68743l-5.29172,-0.00074l6.74262,22.05393l5.32747,0s-6.77837,-22.05357 -6.77837,-22.05357l0,0.00037zm-16.45117,-53.68743l-5.29172,-0.00074l6.74262,22.05393l5.32747,0s-6.77837,-22.05357 -6.77837,-22.05357l0,0.00037zm35.04351,0l-5.32747,0l20.07972,65.67792l2.67921,-8.66193l0,0s-17.43146,-57.016 -17.43146,-57.016z" class="cls-1"/>
      </g>
    </g>
  </svg>
  );

  const fullLogo = (
    <svg width="128" height="78" xmlns="http://www.w3.org/2000/svg">
 <defs>
  <linearGradient id="Adsız_degrade_344" x1="-0.00001" y1="0.5" x2="1.00001" y2="0.5">
   <stop offset="0" stop-color="#00ffe0"/>
   <stop offset="0.55" stop-color="#09f"/>
   <stop offset="0.65" stop-color="#167aff"/>
   <stop offset="0.87" stop-color="#4e2eff"/>
   <stop offset="1" stop-color="#7000ff"/>
  </linearGradient>
 </defs>
 <g>
  <title>Layer 1</title>
  <g id="svg_1" stroke="null">
   <g id="svg_2" stroke="null">
    <polygon class="cls-2" points="18.84513521194458,47.23628616333008 18.84513521194458,51.11211395263672 1.5057785362005234,51.11211395263672 1.5057785362005234,47.23628616333008 12.837000370025635,31.52882194519043 1.7777546048164368,31.52882194519043 1.7777546048164368,27.652996063232422 18.57353639602661,27.652996063232422 18.57353639602661,31.52882194519043 7.275867938995361,47.23628616333008 18.84513521194458,47.23628616333008 " fill="#ffffff" id="svg_3" stroke="null"/>
    <path class="cls-2" d="m38.22464,47.10031l0.23807,3.77375c-1.0879,0.09103 -2.25577,0.16473 -3.50177,0.22112c-1.24674,0.05639 -2.51595,0.09029 -3.80802,0.10208c-1.2917,0.01106 -2.57271,0.01695 -3.84192,0.01695c-1.49586,0 -2.7028,-0.43634 -3.62081,-1.30902c-0.91801,-0.87231 -1.37683,-2.01144 -1.37683,-3.41701l0,-14.21125c0,-1.42805 0.45882,-2.57234 1.37683,-3.43396c0.91801,-0.86125 2.12494,-1.29207 3.62081,-1.29207c1.26922,0 2.55023,0.0059 3.84192,0.01695c1.29207,0.01179 2.56128,0.0398 3.80802,0.08513c1.246,0.0457 2.41387,0.12493 3.50177,0.23807l-0.23807,3.77375l-9.00945,0c-0.5668,0 -0.99208,0.14778 -1.27511,0.44187c-0.28377,0.29482 -0.42491,0.726 -0.42491,1.29207l0,3.67203l9.45169,0l0,3.67166l-9.45169,0l0,4.62394c0,0.5668 0.14115,0.99761 0.42491,1.29207c0.28303,0.29482 0.70831,0.44187 1.27511,0.44187l9.00945,0z" fill="#ffffff" id="svg_4" stroke="null"/>
    <path class="cls-2" d="m59.0311,40.86147c-0.72564,-1.054 -1.99485,-1.66023 -3.80765,-1.81906l0,-0.2038c1.47301,-0.13599 2.53291,-0.64014 3.17894,-1.51282c0.64566,-0.87231 0.96887,-2.14742 0.96887,-3.82497s-0.27198,-2.85611 -0.81593,-3.73984c-0.54395,-0.8841 -1.42289,-1.50139 -2.63499,-1.85297c-1.21283,-0.35121 -2.83879,-0.527 -4.87897,-0.527c-1.33739,0 -2.50453,0.0059 -3.50177,0.01695c-0.99761,0.01179 -1.92078,0.0339 -2.77098,0.06818c-0.84983,0.0339 -1.72841,0.09655 -2.63462,0.18684l0,23.45914c0.99687,0.06781 1.97163,0.12419 2.92355,0.16989c0.95228,0.04533 1.98896,0.07334 3.11113,0.08513c1.1218,0.01106 2.44188,0.01695 3.96059,0.01695c1.88135,0 3.40522,-0.17579 4.57309,-0.527c1.16713,-0.35121 2.02802,-0.96887 2.58376,-1.85297c0.55537,-0.88373 0.83288,-2.1301 0.83288,-3.73984c0,-1.88098 -0.36263,-3.34883 -1.0879,-4.40283l0,0.00002zm-8.2617,-9.70671c0.92906,0 1.65433,0.0796 2.17617,0.23807c0.5211,0.15884 0.88926,0.45882 1.10485,0.90106c0.21522,0.44187 0.32283,1.08274 0.32283,1.92078s-0.10171,1.4509 -0.30588,1.90419c-0.20417,0.45366 -0.54985,0.75954 -1.03704,0.91764c-0.48757,0.15884 -1.15055,0.24986 -1.98896,0.27198l-3.80765,0l0,-6.15371l3.53568,0l0,-0.00001zm3.82497,15.18048c-0.21559,0.48757 -0.59518,0.82182 -1.13913,1.00314c-0.54395,0.18169 -1.2917,0.27198 -2.24361,0.27198c-1.45274,0 -2.77872,-0.00553 -3.97791,-0.01695l0,-6.98696l3.87583,0c0.97439,0.02285 1.73909,0.13599 2.29484,0.34015c0.55501,0.2038 0.94602,0.5609 1.17303,1.07095c0.22628,0.51005 0.33978,1.25263 0.33978,2.22666c0,0.90695 -0.10761,1.60384 -0.32283,2.09104l0,-0.00001z" fill="#ffffff" id="svg_5" stroke="null"/>
    <path class="cls-2" d="m80.51824,43.80234c-0.18169,-0.81593 -0.46472,-1.46712 -0.84983,-1.95505c-0.38585,-0.48683 -0.79897,-0.85499 -1.24084,-1.10485c-0.44224,-0.24913 -0.86715,-0.40796 -1.27511,-0.47577l0,-0.17026c1.13323,-0.13599 2.02839,-0.45292 2.68585,-0.95191c0.65709,-0.49825 1.12733,-1.16124 1.4111,-1.98896c0.28303,-0.82698 0.42491,-1.78479 0.42491,-2.87269c0,-1.79032 -0.30072,-3.18999 -0.90106,-4.19903c-0.6007,-1.0083 -1.54156,-1.72214 -2.82183,-2.1419c-1.28064,-0.41902 -2.95266,-0.62908 -5.01459,-0.62908c-1.40594,0 -2.60698,0.0059 -3.60422,0.01732c-0.99761,0.01142 -1.91562,0.0398 -2.75366,0.08476c-0.83877,0.0457 -1.73393,0.12493 -2.68585,0.23807l0,23.45914l5.13362,0l0,-9.19445c1.01935,0.01032 2.2543,0.01474 3.70594,0.01474c0.70242,0 1.25226,0.14778 1.6488,0.44187c0.39654,0.29482 0.69726,0.82772 0.90106,1.59795l1.80211,7.1399l5.47377,0l-2.04018,-7.30979l0.00001,-0.00001zm-4.40283,-7.10562c-0.21559,0.49862 -0.58965,0.84467 -1.1218,1.03704c-0.53289,0.19274 -1.25263,0.28893 -2.15885,0.28893l-3.80802,0l0,-6.78464c1.12438,-0.00995 2.39397,-0.01511 3.80802,-0.01511c0.90622,0 1.62595,0.09655 2.15885,0.28893c0.53216,0.19274 0.90622,0.53289 1.1218,1.02009c0.21522,0.48757 0.3232,1.18446 0.3232,2.09067s-0.10798,1.57583 -0.3232,2.07409z" fill="#ffffff" id="svg_6" stroke="null"/>
    <path class="cls-2" d="m98.91119,29.01286c-0.11351,-0.40796 -0.33463,-0.73632 -0.66299,-0.98582c-0.32873,-0.24913 -0.70868,-0.37406 -1.13876,-0.37406l-4.92982,0c-0.43081,0 -0.81077,0.12493 -1.13913,0.37406c-0.32873,0.24949 -0.54985,0.57785 -0.66299,0.98582l-6.83365,22.09926l5.33779,0l1.72067,-6.18762l8.08407,0l1.72067,6.18762l5.30388,0l-6.79975,-22.09926l0.00001,0zm-7.22134,12.00154l1.98564,-7.13953c0.11314,-0.38511 0.20932,-0.77612 0.28893,-1.17303c0.07923,-0.39617 0.15294,-0.77612 0.22112,-1.13913l0.91801,0c0.06781,0.363 0.14704,0.74296 0.2377,1.13913c0.09029,0.39691 0.18132,0.78792 0.27234,1.17303l1.98527,7.13953l-5.90901,0z" fill="#ffffff" id="svg_7" stroke="null"/>
   </g>
   <g id="svg_8" stroke="null">
    <path class="cls-2" d="m13.25895,71.26297c0,1.14613 -0.26866,2.1559 -0.80487,3.02895c-0.53695,0.87342 -1.27364,1.55556 -2.21081,2.04682c-0.93717,0.49125 -2.01512,0.73706 -3.23385,0.73706s-2.26056,-0.23696 -3.23385,-0.70979c-0.97329,-0.47282 -1.78737,-1.15497 -2.44225,-2.04682l0.98213,-0.95486c0.49125,0.7459 1.1465,1.32818 1.965,1.74646c0.81887,0.41865 1.71919,0.62761 2.70169,0.62761s1.80543,-0.18648 2.52443,-0.55943c0.71863,-0.37258 1.27806,-0.90069 1.67829,-1.58284c0.40022,-0.68215 0.60034,-1.46896 0.60034,-2.3608s-0.20011,-1.72804 -0.60034,-2.40134c-0.40022,-0.67294 -0.96444,-1.20067 -1.69192,-1.58284c-0.72785,-0.38217 -1.59205,-0.57306 -2.59261,-0.57306c-0.29114,0 -0.59149,0.01843 -0.90069,0.05454c-0.30957,0.03648 -0.62761,0.10024 -0.95486,0.1909l0,-0.90032l5.89206,-6.54988l-8.83956,0l0,-1.28248l10.9435,0l0,0.92759l-5.93702,6.5134c0.15294,-0.01216 0.31251,-0.01806 0.47872,-0.01806c1.07353,0 2.0376,0.24139 2.89296,0.72306c0.85499,0.48241 1.53235,1.15092 2.03318,2.00591c0.5001,0.85536 0.75033,1.82828 0.75033,2.92023l0,-0.00001z" fill="#ffffff" id="svg_9" stroke="null"/>
    <path class="cls-2" d="m28.00936,67.64696c-0.55537,-0.91875 -1.29207,-1.65544 -2.21081,-2.21081c-0.91875,-0.55464 -1.93331,-0.83214 -3.04258,-0.83214c-0.47356,0 -0.92796,0.05454 -1.36467,0.16363c-0.4146,0.10356 -0.79234,0.24839 -1.13249,0.43376l5.44429,-7.01054l-1.71919,0l-6.24953,8.24143c-1.05547,1.40152 -1.58284,2.86569 -1.58284,4.39398c0,1.16455 0.28193,2.21966 0.84614,3.16567c0.56348,0.94602 1.32339,1.69671 2.27862,2.25135c0.95523,0.55537 2.01475,0.83251 3.17931,0.83251s2.25577,-0.28193 3.22021,-0.84614c0.96407,-0.56385 1.73283,-1.32781 2.30589,-2.29226c0.57343,-0.96407 0.85978,-2.01954 0.85978,-3.16567s-0.2775,-2.20602 -0.83214,-3.12476l0.00001,-0.00001zm-1.28285,5.62155c-0.44592,0.75549 -1.04183,1.36467 -1.78737,1.82865c-0.74627,0.46398 -1.56515,0.69578 -2.45625,0.69578s-1.73283,-0.22259 -2.46989,-0.66851c-0.73669,-0.44555 -1.32339,-1.05068 -1.7601,-1.81464c-0.43671,-0.76433 -0.65488,-1.61048 -0.65488,-2.53807s0.21817,-1.79179 0.65488,-2.53807c0.43671,-0.7459 1.02341,-1.34145 1.7601,-1.78774c0.73706,-0.44555 1.55999,-0.66851 2.46989,-0.66851s1.70998,0.22296 2.45625,0.66851c0.74554,0.44629 1.34145,1.05068 1.78737,1.81501c0.44555,0.76396 0.66851,1.60126 0.66851,2.51079s-0.22296,1.74204 -0.66851,2.49679l0,0.00001z" fill="#ffffff" id="svg_10" stroke="null"/>
    <path class="cls-2" d="m45.56981,62.4072c-0.65488,-1.42805 -1.54156,-2.53327 -2.66079,-3.31603c-1.11886,-0.78202 -2.37002,-1.1734 -3.75237,-1.1734s-2.57897,0.39138 -3.69783,1.1734c-1.11886,0.78276 -2.00591,1.88319 -2.66079,3.3024c-0.65488,1.41884 -0.9825,3.11113 -0.9825,5.07576s0.33168,3.63923 0.99614,5.07613c0.66409,1.43727 1.55999,2.5517 2.68806,3.34293c1.12807,0.7916 2.37444,1.1874 3.73874,1.1874s2.57455,-0.39101 3.68419,-1.17377c1.10964,-0.78202 1.99227,-1.89203 2.64715,-3.3293c0.65525,-1.4369 0.9825,-3.12882 0.9825,-5.07613s-0.32725,-3.66098 -0.9825,-5.0894l0,0.00001zm-1.24158,9.51065c-0.51852,1.23752 -1.22389,2.17875 -2.11499,2.82441c-0.89147,0.64603 -1.90125,0.96887 -3.02932,0.96887s-2.13784,-0.32283 -3.02895,-0.96887c-0.89184,-0.64566 -1.59647,-1.58689 -2.11499,-2.82441c-0.51889,-1.23715 -0.77797,-2.71975 -0.77797,-4.44852s0.25908,-3.20179 0.77797,-4.42088c0.51852,-1.21873 1.22315,-2.15111 2.11499,-2.79714c0.89111,-0.64566 1.90088,-0.96887 3.02895,-0.96887s2.13784,0.3232 3.02932,0.96887c0.89111,0.64603 1.59647,1.58284 2.11499,2.81078c0.51852,1.22794 0.77797,2.69727 0.77797,4.40725s-0.25945,3.21137 -0.77797,4.44852l0,-0.00001z" fill="#ffffff" id="svg_11" stroke="null"/>
   </g>
  </g>
  <path class="cls-1" d="m113.7788,77.07506l-5.32747,0s-6.74262,-22.05393 -6.74262,-22.05393l5.29172,0.00074l6.77837,22.05357l0,-0.00037l0,-0.00001zm-23.22954,-75.74063l-5.29172,-0.00074l6.74262,22.05393l5.32747,0s-6.77837,-22.05357 -6.77837,-22.05357l0,0.00037l0,0.00001zm23.33679,-0.00037l-5.32747,0l14.29749,46.76497l2.67921,-8.6623l-11.64923,-38.10267zm-18.55474,53.68743l-5.29172,-0.00074l6.74262,22.05393l5.32747,0s-6.77837,-22.05357 -6.77837,-22.05357l0,0.00037l0,0.00001zm-16.45117,-53.68743l-5.29172,-0.00074l6.74262,22.05393l5.32747,0s-6.77837,-22.05357 -6.77837,-22.05357l0,0.00037l0,0.00001zm4.78204,53.68743l-5.29172,-0.00074l6.74262,22.05393l5.32747,0s-6.77837,-22.05357 -6.77837,-22.05357l0,0.00037l0,0.00001zm-16.45117,-53.68743l-5.29172,-0.00074l6.74262,22.05393l5.32747,0s-6.77837,-22.05357 -6.77837,-22.05357l0,0.00037l0,0.00001zm35.04351,0l-5.32747,0l20.07972,65.67792l2.67921,-8.66193l0,0s-17.43146,-57.016 -17.43146,-57.016l0,0.00001z" fill="url(#Adsız_degrade_344)" id="svg_12" stroke="null"/>
 </g>
</svg>
  );

  return (
    <Link
      component={RouterLink}
      href="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: single ? 78 : 128,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        {single ? singleLogo : fullLogo}
      </Box>
    </Link>
  );
}

Logo.propTypes = {
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(Logo);
