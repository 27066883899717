import { useMemo } from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { shadows } from './shadows';
import { palette } from './palette';
import { typography } from './typography';
import { customShadows } from './custom-shadows';
import { componentsOverrides } from './overrides';

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette: palette('light'), // using palette('dark') for dark theme
      typography,
      shape: { borderRadius: 8 },
      shadows: shadows('light'), // using shadows('dark') for dark theme
      customShadows: customShadows('light'), // using customShadows('dark') for dark theme
    }),
    []
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverrides(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

// Added prop types validation
ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};