import { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

function Logo({ single = false, sx }) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const singleLogo = (
<svg width="96" height="52" xmlns="http://www.w3.org/2000/svg">
 <defs>
  <linearGradient id="Adsız_degrade_344" x1="-0.00001" y1="0.5" x2="1.00001" y2="0.5">
   <stop offset="0" stop-color="#00ffe0"/>
   <stop offset="0.55" stop-color="#09f"/>
   <stop offset="0.65" stop-color="#167aff"/>
   <stop offset="0.87" stop-color="#4e2eff"/>
   <stop offset="1" stop-color="#7000ff"/>
  </linearGradient>
 </defs>
 <g>
  <title>Layer 1</title>
  <g stroke="null" id="svg_14">
   <g stroke="null" id="svg_1">
    <g stroke="null" id="svg_2">
     <polygon stroke="null" class="cls-2" points="15.960464477539062,31.811046600341797 15.960464477539062,34.38444137573242 4.447828769683838,34.38444137573242 4.447828769683838,31.811046600341797 11.971304893493652,21.381919860839844 4.6284098625183105,21.381919860839844 4.6284098625183105,18.808530807495117 15.780132293701172,18.808530807495117 15.780132293701172,21.381919860839844 8.278936386108398,31.811046600341797 15.960464477539062,31.811046600341797 " fill="#1e1e1e" id="svg_3"/>
     <path stroke="null" class="cls-2" d="m28.82767,31.72076l0.15807,2.50562c-0.72232,0.06044 -1.49774,0.10937 -2.32503,0.14682c-0.82778,0.03744 -1.67049,0.05995 -2.52837,0.06778c-0.85764,0.00734 -1.70818,0.01126 -2.55088,0.01126c-0.99319,0 -1.79455,-0.28971 -2.40407,-0.86913c-0.60953,-0.57918 -0.91416,-1.33551 -0.91416,-2.26876l0,-9.4357c0,-0.94817 0.30464,-1.70793 0.91416,-2.28001c0.60953,-0.57183 1.41087,-0.85788 2.40407,-0.85788c0.84271,0 1.69325,0.00392 2.55088,0.01126c0.85788,0.00782 1.70059,0.02642 2.52837,0.05652c0.82729,0.03035 1.60271,0.08295 2.32503,0.15807l-0.15807,2.50562l-5.98191,0c-0.37633,0 -0.6587,0.09812 -0.84662,0.29339c-0.18841,0.19575 -0.28212,0.48204 -0.28212,0.85788l0,2.43808l6.27554,0l0,2.43784l-6.27554,0l0,3.07011c0,0.37633 0.09372,0.66237 0.28212,0.85788c0.18792,0.19575 0.47029,0.29339 0.84662,0.29339l5.98191,0l0,-0.00002z" fill="#1e1e1e" id="svg_4"/>
     <path stroke="null" class="cls-2" d="m42.64233,27.57843c-0.4818,-0.69981 -1.3245,-1.10233 -2.52813,-1.20778l0,-0.13531c0.97802,-0.09029 1.68175,-0.42503 2.11069,-1.00445c0.42869,-0.57918 0.64329,-1.4258 0.64329,-2.53962s-0.18059,-1.89634 -0.54175,-2.4831c-0.36116,-0.587 -0.94474,-0.99686 -1.74953,-1.2303c-0.80527,-0.23319 -1.88484,-0.34991 -3.23944,-0.34991c-0.88797,0 -1.66291,0.00392 -2.32503,0.01126c-0.66237,0.00782 -1.27532,0.02251 -1.83982,0.04527c-0.56425,0.02251 -1.14759,0.0641 -1.74928,0.12406l0,15.57592c0.66188,0.04502 1.30908,0.08246 1.94112,0.1128c0.63228,0.0301 1.32059,0.0487 2.06566,0.05652c0.74483,0.00734 1.62131,0.01126 2.62967,0.01126c1.24914,0 2.26093,-0.11672 3.03635,-0.34991c0.77493,-0.23319 1.34653,-0.64329 1.71551,-1.2303c0.36875,-0.58676 0.553,-1.4143 0.553,-2.4831c0,-1.2489 -0.24077,-2.22349 -0.72232,-2.9233l0,0.00001l0.00001,0zm-5.48543,-6.44487c0.61686,0 1.09841,0.05285 1.44489,0.15807c0.34599,0.10547 0.59043,0.30464 0.73358,0.59827c0.1429,0.29339 0.21434,0.71889 0.21434,1.27532s-0.06753,0.96334 -0.20309,1.2643c-0.13556,0.30121 -0.36507,0.5043 -0.68855,0.60927c-0.32372,0.10547 -0.76392,0.1659 -1.32059,0.18059l-2.52813,0l0,-4.08582l2.34755,0l0,-0.00001l0.00001,0.00001zm2.53962,10.07923c-0.14315,0.32372 -0.39517,0.54565 -0.75633,0.66605c-0.36116,0.12063 -0.85764,0.18059 -1.48966,0.18059c-0.96456,0 -1.84496,-0.00367 -2.64117,-0.01126l0,-4.63906l2.57339,0c0.64695,0.01517 1.15468,0.09029 1.52368,0.22585c0.36851,0.13531 0.62812,0.37242 0.77885,0.71107c0.15024,0.33865 0.2256,0.83169 0.2256,1.47841c0,0.60218 -0.07145,1.06489 -0.21434,1.38837l0,-0.00001l-0.00001,-0.00001z" fill="#1e1e1e" id="svg_5"/>
     <path stroke="null" class="cls-2" d="m56.90892,29.53104c-0.12063,-0.54175 -0.30855,-0.97411 -0.56425,-1.29807c-0.25619,-0.32323 -0.53048,-0.56768 -0.82387,-0.73358c-0.29363,-0.16541 -0.57575,-0.27087 -0.84662,-0.31589l0,-0.11305c0.75242,-0.09029 1.34677,-0.30072 1.7833,-0.63203c0.43628,-0.33082 0.7485,-0.77102 0.93691,-1.32059c0.18792,-0.54908 0.28212,-1.18503 0.28212,-1.90735c0,-1.1887 -0.19967,-2.11803 -0.59827,-2.78799c-0.39884,-0.66947 -1.02354,-1.14343 -1.87358,-1.42214c-0.85029,-0.27821 -1.96045,-0.41768 -3.32948,-0.41768c-0.93349,0 -1.73093,0.00392 -2.39306,0.0115c-0.66237,0.00758 -1.2719,0.02642 -1.82832,0.05628c-0.55691,0.03035 -1.15126,0.08295 -1.7833,0.15807l0,15.57592l3.40852,0l0,-6.10474c0.67681,0.00685 1.49677,0.00979 2.46059,0.00979c0.46638,0 0.83145,0.09812 1.09473,0.29339c0.26329,0.19575 0.46295,0.54957 0.59827,1.06098l1.19653,4.74061l3.63436,0l-1.3546,-4.85341l0.00001,-0.00001l-0.00001,0zm-2.9233,-4.71784c-0.14315,0.33107 -0.3915,0.56083 -0.74483,0.68855c-0.35382,0.12797 -0.83169,0.19184 -1.43339,0.19184l-2.52837,0l0,-4.50472c0.74654,-0.00661 1.5895,-0.01003 2.52837,-0.01003c0.60169,0 1.07956,0.0641 1.43339,0.19184c0.35333,0.12797 0.60169,0.35382 0.74483,0.6773c0.1429,0.32372 0.21459,0.78643 0.21459,1.38812s-0.0717,1.04629 -0.21459,1.37711z" fill="#1e1e1e" id="svg_6"/>
     <path stroke="null" class="cls-2" d="m69.1211,19.71143c-0.07537,-0.27087 -0.22218,-0.48889 -0.4402,-0.65455c-0.21827,-0.16541 -0.47053,-0.24836 -0.75609,-0.24836l-3.2732,0c-0.28604,0 -0.53832,0.08295 -0.75633,0.24836c-0.21827,0.16565 -0.36507,0.38367 -0.4402,0.65455l-4.53727,14.67302l3.54408,0l1.14246,-4.10833l5.3675,0l1.14246,4.10833l3.52156,0l-4.51476,-14.67302l0.00001,0l-0.00002,0zm-4.79468,7.96854l1.31839,-4.74036c0.07512,-0.2557 0.13898,-0.51531 0.19184,-0.77885c0.05261,-0.26304 0.10154,-0.51531 0.14682,-0.75633l0.60953,0c0.04502,0.24102 0.09763,0.49329 0.15782,0.75633c0.05995,0.26353 0.12039,0.52315 0.18082,0.77885l1.31814,4.74036l-3.92335,0l-0.00001,0z" fill="#1e1e1e" id="svg_7"/>
    </g>
    <g stroke="null" id="svg_8">
     <path stroke="null" class="cls-2" d="m12.25146,47.7638c0,0.76099 -0.17838,1.43143 -0.5344,2.0111c-0.35651,0.57992 -0.84565,1.03283 -1.46789,1.35901c-0.62225,0.32617 -1.33796,0.48938 -2.14715,0.48938s-1.50092,-0.15733 -2.14715,-0.47127c-0.64622,-0.31393 -1.18674,-0.76686 -1.62155,-1.35901l0.65209,-0.63399c0.32617,0.49525 0.76123,0.88186 1.30468,1.15958c0.54369,0.27797 1.14147,0.41671 1.79381,0.41671s1.19874,-0.12382 1.67612,-0.37144c0.47714,-0.24738 0.84858,-0.59802 1.11432,-1.05094c0.26573,-0.45292 0.3986,-0.97533 0.3986,-1.56747s-0.13286,-1.14735 -0.3986,-1.59439c-0.26573,-0.44681 -0.64035,-0.79719 -1.12336,-1.05094c-0.48326,-0.25375 -1.05706,-0.38049 -1.72139,-0.38049c-0.19331,0 -0.39272,0.01224 -0.59802,0.03622c-0.20554,0.02422 -0.41671,0.06655 -0.63399,0.12675l0,-0.59778l3.91209,-4.34886l-5.86911,0l0,-0.85152l7.26605,0l0,0.61589l-3.94194,4.32464c0.10154,-0.00808 0.20749,-0.01199 0.31785,-0.01199c0.71278,0 1.35289,0.16027 1.92081,0.48008c0.56768,0.3203 1.01742,0.76417 1.34995,1.33184c0.33205,0.56792 0.49819,1.2139 0.49819,1.93892l0,-0.00001l-0.00001,-0.00001z" fill="#1e1e1e" id="svg_9"/>
     <path stroke="null" class="cls-2" d="m22.04514,45.36291c-0.36875,-0.61002 -0.85788,-1.09915 -1.46789,-1.46789c-0.61002,-0.36826 -1.28364,-0.55251 -2.02015,-0.55251c-0.31442,0 -0.61613,0.03622 -0.90609,0.10865c-0.27528,0.06876 -0.52608,0.16492 -0.75193,0.288l3.61479,-4.65471l-1.14147,0l-4.14944,5.47198c-0.70079,0.93055 -1.05094,1.9027 -1.05094,2.91743c0,0.77322 0.18719,1.47376 0.5618,2.10188c0.37413,0.62812 0.87868,1.12654 1.51291,1.49481c0.63423,0.36875 1.33771,0.55275 2.11094,0.55275s1.49774,-0.18719 2.13809,-0.5618c0.6401,-0.37437 1.15053,-0.88161 1.53102,-1.52197c0.38073,-0.6401 0.57086,-1.34089 0.57086,-2.10188s-0.18425,-1.46471 -0.55251,-2.07471l0.00001,-0.00001l-0.00001,-0.00001zm-0.85176,3.73248c-0.29607,0.50161 -0.69173,0.90609 -1.18674,1.21415c-0.49549,0.30806 -1.0392,0.46197 -1.63085,0.46197s-1.15053,-0.14779 -1.63991,-0.44387c-0.48913,-0.29582 -0.87868,-0.69761 -1.16864,-1.20484c-0.28996,-0.50748 -0.43482,-1.06929 -0.43482,-1.68518s0.14485,-1.18968 0.43482,-1.68518c0.28996,-0.49525 0.6795,-0.89067 1.16864,-1.18699c0.48938,-0.29582 1.03577,-0.44387 1.63991,-0.44387s1.13536,0.14803 1.63085,0.44387c0.49501,0.29632 0.89067,0.69761 1.18674,1.2051c0.29582,0.50724 0.44387,1.06317 0.44387,1.66706s-0.14803,1.15664 -0.44387,1.65777l0,0.00001l0,0.00001z" fill="#1e1e1e" id="svg_10"/>
     <path stroke="null" class="cls-2" d="m33.70458,41.88392c-0.43482,-0.94817 -1.02354,-1.68199 -1.76666,-2.20171c-0.74288,-0.51923 -1.5736,-0.77909 -2.49142,-0.77909s-1.71233,0.25986 -2.45521,0.77909c-0.74288,0.51972 -1.33184,1.25036 -1.76666,2.19266c-0.43482,0.94206 -0.65234,2.06566 -0.65234,3.3701s0.22022,2.4163 0.6614,3.37034c0.44093,0.95429 1.03577,1.69423 1.78477,2.21957c0.74899,0.52559 1.57653,0.78839 2.48237,0.78839s1.70939,-0.25962 2.44616,-0.77934c0.73676,-0.51923 1.32279,-1.25623 1.7576,-2.21052c0.43506,-0.95404 0.65234,-2.07741 0.65234,-3.37034s-0.21728,-2.43075 -0.65234,-3.37915l0,0.00001l-0.00001,0zm-0.82436,6.31469c-0.34428,0.82166 -0.81261,1.4466 -1.40427,1.87529c-0.5919,0.42894 -1.26235,0.64329 -2.01135,0.64329s-1.41944,-0.21434 -2.0111,-0.64329c-0.59215,-0.42869 -1.05999,-1.05363 -1.40427,-1.87529c-0.34452,-0.82142 -0.51654,-1.8058 -0.51654,-2.95364s0.17202,-2.12586 0.51654,-2.93529c0.34428,-0.80919 0.81212,-1.42825 1.40427,-1.85719c0.59166,-0.42869 1.26211,-0.64329 2.0111,-0.64329s1.41944,0.21459 2.01135,0.64329c0.59166,0.42894 1.05999,1.05094 1.40427,1.86625c0.34428,0.8153 0.51654,1.79088 0.51654,2.92624s-0.17226,2.13222 -0.51654,2.95364l0,-0.00001z" fill="#1e1e1e" id="svg_11"/>
    </g>
   </g>
   <path stroke="null" class="cls-1" d="m78.9926,51.62279l-3.53723,0s-4.47683,-14.64292 -4.47683,-14.64292l3.51349,0.00049l4.50056,14.64269l0,-0.00025l0,-0.00001l0.00001,0zm-15.42348,-50.28873l-3.51349,-0.00049l4.47683,14.64292l3.53723,0s-4.50056,-14.64269 -4.50056,-14.64269l0,0.00025l0,0.00001l-0.00001,0zm15.49469,-0.00025l-3.53723,0l9.49296,31.05006l1.77889,-5.75142l-7.73462,-25.29864zm-12.3196,35.64629l-3.51349,-0.00049l4.47683,14.64292l3.53723,0s-4.50056,-14.64269 -4.50056,-14.64269l0,0.00025l0,0.00001l-0.00001,0zm-10.92292,-35.64629l-3.51349,-0.00049l4.47683,14.64292l3.53723,0s-4.50056,-14.64269 -4.50056,-14.64269l0,0.00025l0,0.00001l-0.00001,0zm3.17508,35.64629l-3.51349,-0.00049l4.47683,14.64292l3.53723,0s-4.50056,-14.64269 -4.50056,-14.64269l0,0.00025l0,0.00001l-0.00001,0zm-10.92292,-35.64629l-3.51349,-0.00049l4.47683,14.64292l3.53723,0s-4.50056,-14.64269 -4.50056,-14.64269l0,0.00025l0,0.00001l-0.00001,0zm23.26748,0l-3.53723,0l13.33212,43.60749l1.77889,-5.75117l0,0s-11.57379,-37.85633 -11.57379,-37.85633l0,0.00001z" fill="url(#Adsız_degrade_344)" id="svg_12"/>
  </g>
 </g>
</svg>
  );

  const fullLogo = (
<svg width="96" height="52" xmlns="http://www.w3.org/2000/svg">
 <defs>
  <linearGradient id="Adsız_degrade_344" x1="-0.00001" y1="0.5" x2="1.00001" y2="0.5">
   <stop offset="0" stop-color="#00ffe0"/>
   <stop offset="0.55" stop-color="#09f"/>
   <stop offset="0.65" stop-color="#167aff"/>
   <stop offset="0.87" stop-color="#4e2eff"/>
   <stop offset="1" stop-color="#7000ff"/>
  </linearGradient>
 </defs>
 <g>
  <title>Layer 1</title>
  <g stroke="null" id="svg_14">
   <g stroke="null" id="svg_1">
    <g stroke="null" id="svg_2">
     <polygon stroke="null" class="cls-2" points="15.960464477539062,31.811046600341797 15.960464477539062,34.38444137573242 4.447828769683838,34.38444137573242 4.447828769683838,31.811046600341797 11.971304893493652,21.381919860839844 4.6284098625183105,21.381919860839844 4.6284098625183105,18.808530807495117 15.780132293701172,18.808530807495117 15.780132293701172,21.381919860839844 8.278936386108398,31.811046600341797 15.960464477539062,31.811046600341797 " fill="#1e1e1e" id="svg_3"/>
     <path stroke="null" class="cls-2" d="m28.82767,31.72076l0.15807,2.50562c-0.72232,0.06044 -1.49774,0.10937 -2.32503,0.14682c-0.82778,0.03744 -1.67049,0.05995 -2.52837,0.06778c-0.85764,0.00734 -1.70818,0.01126 -2.55088,0.01126c-0.99319,0 -1.79455,-0.28971 -2.40407,-0.86913c-0.60953,-0.57918 -0.91416,-1.33551 -0.91416,-2.26876l0,-9.4357c0,-0.94817 0.30464,-1.70793 0.91416,-2.28001c0.60953,-0.57183 1.41087,-0.85788 2.40407,-0.85788c0.84271,0 1.69325,0.00392 2.55088,0.01126c0.85788,0.00782 1.70059,0.02642 2.52837,0.05652c0.82729,0.03035 1.60271,0.08295 2.32503,0.15807l-0.15807,2.50562l-5.98191,0c-0.37633,0 -0.6587,0.09812 -0.84662,0.29339c-0.18841,0.19575 -0.28212,0.48204 -0.28212,0.85788l0,2.43808l6.27554,0l0,2.43784l-6.27554,0l0,3.07011c0,0.37633 0.09372,0.66237 0.28212,0.85788c0.18792,0.19575 0.47029,0.29339 0.84662,0.29339l5.98191,0l0,-0.00002z" fill="#1e1e1e" id="svg_4"/>
     <path stroke="null" class="cls-2" d="m42.64233,27.57843c-0.4818,-0.69981 -1.3245,-1.10233 -2.52813,-1.20778l0,-0.13531c0.97802,-0.09029 1.68175,-0.42503 2.11069,-1.00445c0.42869,-0.57918 0.64329,-1.4258 0.64329,-2.53962s-0.18059,-1.89634 -0.54175,-2.4831c-0.36116,-0.587 -0.94474,-0.99686 -1.74953,-1.2303c-0.80527,-0.23319 -1.88484,-0.34991 -3.23944,-0.34991c-0.88797,0 -1.66291,0.00392 -2.32503,0.01126c-0.66237,0.00782 -1.27532,0.02251 -1.83982,0.04527c-0.56425,0.02251 -1.14759,0.0641 -1.74928,0.12406l0,15.57592c0.66188,0.04502 1.30908,0.08246 1.94112,0.1128c0.63228,0.0301 1.32059,0.0487 2.06566,0.05652c0.74483,0.00734 1.62131,0.01126 2.62967,0.01126c1.24914,0 2.26093,-0.11672 3.03635,-0.34991c0.77493,-0.23319 1.34653,-0.64329 1.71551,-1.2303c0.36875,-0.58676 0.553,-1.4143 0.553,-2.4831c0,-1.2489 -0.24077,-2.22349 -0.72232,-2.9233l0,0.00001l0.00001,0zm-5.48543,-6.44487c0.61686,0 1.09841,0.05285 1.44489,0.15807c0.34599,0.10547 0.59043,0.30464 0.73358,0.59827c0.1429,0.29339 0.21434,0.71889 0.21434,1.27532s-0.06753,0.96334 -0.20309,1.2643c-0.13556,0.30121 -0.36507,0.5043 -0.68855,0.60927c-0.32372,0.10547 -0.76392,0.1659 -1.32059,0.18059l-2.52813,0l0,-4.08582l2.34755,0l0,-0.00001l0.00001,0.00001zm2.53962,10.07923c-0.14315,0.32372 -0.39517,0.54565 -0.75633,0.66605c-0.36116,0.12063 -0.85764,0.18059 -1.48966,0.18059c-0.96456,0 -1.84496,-0.00367 -2.64117,-0.01126l0,-4.63906l2.57339,0c0.64695,0.01517 1.15468,0.09029 1.52368,0.22585c0.36851,0.13531 0.62812,0.37242 0.77885,0.71107c0.15024,0.33865 0.2256,0.83169 0.2256,1.47841c0,0.60218 -0.07145,1.06489 -0.21434,1.38837l0,-0.00001l-0.00001,-0.00001z" fill="#1e1e1e" id="svg_5"/>
     <path stroke="null" class="cls-2" d="m56.90892,29.53104c-0.12063,-0.54175 -0.30855,-0.97411 -0.56425,-1.29807c-0.25619,-0.32323 -0.53048,-0.56768 -0.82387,-0.73358c-0.29363,-0.16541 -0.57575,-0.27087 -0.84662,-0.31589l0,-0.11305c0.75242,-0.09029 1.34677,-0.30072 1.7833,-0.63203c0.43628,-0.33082 0.7485,-0.77102 0.93691,-1.32059c0.18792,-0.54908 0.28212,-1.18503 0.28212,-1.90735c0,-1.1887 -0.19967,-2.11803 -0.59827,-2.78799c-0.39884,-0.66947 -1.02354,-1.14343 -1.87358,-1.42214c-0.85029,-0.27821 -1.96045,-0.41768 -3.32948,-0.41768c-0.93349,0 -1.73093,0.00392 -2.39306,0.0115c-0.66237,0.00758 -1.2719,0.02642 -1.82832,0.05628c-0.55691,0.03035 -1.15126,0.08295 -1.7833,0.15807l0,15.57592l3.40852,0l0,-6.10474c0.67681,0.00685 1.49677,0.00979 2.46059,0.00979c0.46638,0 0.83145,0.09812 1.09473,0.29339c0.26329,0.19575 0.46295,0.54957 0.59827,1.06098l1.19653,4.74061l3.63436,0l-1.3546,-4.85341l0.00001,-0.00001l-0.00001,0zm-2.9233,-4.71784c-0.14315,0.33107 -0.3915,0.56083 -0.74483,0.68855c-0.35382,0.12797 -0.83169,0.19184 -1.43339,0.19184l-2.52837,0l0,-4.50472c0.74654,-0.00661 1.5895,-0.01003 2.52837,-0.01003c0.60169,0 1.07956,0.0641 1.43339,0.19184c0.35333,0.12797 0.60169,0.35382 0.74483,0.6773c0.1429,0.32372 0.21459,0.78643 0.21459,1.38812s-0.0717,1.04629 -0.21459,1.37711z" fill="#1e1e1e" id="svg_6"/>
     <path stroke="null" class="cls-2" d="m69.1211,19.71143c-0.07537,-0.27087 -0.22218,-0.48889 -0.4402,-0.65455c-0.21827,-0.16541 -0.47053,-0.24836 -0.75609,-0.24836l-3.2732,0c-0.28604,0 -0.53832,0.08295 -0.75633,0.24836c-0.21827,0.16565 -0.36507,0.38367 -0.4402,0.65455l-4.53727,14.67302l3.54408,0l1.14246,-4.10833l5.3675,0l1.14246,4.10833l3.52156,0l-4.51476,-14.67302l0.00001,0l-0.00002,0zm-4.79468,7.96854l1.31839,-4.74036c0.07512,-0.2557 0.13898,-0.51531 0.19184,-0.77885c0.05261,-0.26304 0.10154,-0.51531 0.14682,-0.75633l0.60953,0c0.04502,0.24102 0.09763,0.49329 0.15782,0.75633c0.05995,0.26353 0.12039,0.52315 0.18082,0.77885l1.31814,4.74036l-3.92335,0l-0.00001,0z" fill="#1e1e1e" id="svg_7"/>
    </g>
    <g stroke="null" id="svg_8">
     <path stroke="null" class="cls-2" d="m12.25146,47.7638c0,0.76099 -0.17838,1.43143 -0.5344,2.0111c-0.35651,0.57992 -0.84565,1.03283 -1.46789,1.35901c-0.62225,0.32617 -1.33796,0.48938 -2.14715,0.48938s-1.50092,-0.15733 -2.14715,-0.47127c-0.64622,-0.31393 -1.18674,-0.76686 -1.62155,-1.35901l0.65209,-0.63399c0.32617,0.49525 0.76123,0.88186 1.30468,1.15958c0.54369,0.27797 1.14147,0.41671 1.79381,0.41671s1.19874,-0.12382 1.67612,-0.37144c0.47714,-0.24738 0.84858,-0.59802 1.11432,-1.05094c0.26573,-0.45292 0.3986,-0.97533 0.3986,-1.56747s-0.13286,-1.14735 -0.3986,-1.59439c-0.26573,-0.44681 -0.64035,-0.79719 -1.12336,-1.05094c-0.48326,-0.25375 -1.05706,-0.38049 -1.72139,-0.38049c-0.19331,0 -0.39272,0.01224 -0.59802,0.03622c-0.20554,0.02422 -0.41671,0.06655 -0.63399,0.12675l0,-0.59778l3.91209,-4.34886l-5.86911,0l0,-0.85152l7.26605,0l0,0.61589l-3.94194,4.32464c0.10154,-0.00808 0.20749,-0.01199 0.31785,-0.01199c0.71278,0 1.35289,0.16027 1.92081,0.48008c0.56768,0.3203 1.01742,0.76417 1.34995,1.33184c0.33205,0.56792 0.49819,1.2139 0.49819,1.93892l0,-0.00001l-0.00001,-0.00001z" fill="#1e1e1e" id="svg_9"/>
     <path stroke="null" class="cls-2" d="m22.04514,45.36291c-0.36875,-0.61002 -0.85788,-1.09915 -1.46789,-1.46789c-0.61002,-0.36826 -1.28364,-0.55251 -2.02015,-0.55251c-0.31442,0 -0.61613,0.03622 -0.90609,0.10865c-0.27528,0.06876 -0.52608,0.16492 -0.75193,0.288l3.61479,-4.65471l-1.14147,0l-4.14944,5.47198c-0.70079,0.93055 -1.05094,1.9027 -1.05094,2.91743c0,0.77322 0.18719,1.47376 0.5618,2.10188c0.37413,0.62812 0.87868,1.12654 1.51291,1.49481c0.63423,0.36875 1.33771,0.55275 2.11094,0.55275s1.49774,-0.18719 2.13809,-0.5618c0.6401,-0.37437 1.15053,-0.88161 1.53102,-1.52197c0.38073,-0.6401 0.57086,-1.34089 0.57086,-2.10188s-0.18425,-1.46471 -0.55251,-2.07471l0.00001,-0.00001l-0.00001,-0.00001zm-0.85176,3.73248c-0.29607,0.50161 -0.69173,0.90609 -1.18674,1.21415c-0.49549,0.30806 -1.0392,0.46197 -1.63085,0.46197s-1.15053,-0.14779 -1.63991,-0.44387c-0.48913,-0.29582 -0.87868,-0.69761 -1.16864,-1.20484c-0.28996,-0.50748 -0.43482,-1.06929 -0.43482,-1.68518s0.14485,-1.18968 0.43482,-1.68518c0.28996,-0.49525 0.6795,-0.89067 1.16864,-1.18699c0.48938,-0.29582 1.03577,-0.44387 1.63991,-0.44387s1.13536,0.14803 1.63085,0.44387c0.49501,0.29632 0.89067,0.69761 1.18674,1.2051c0.29582,0.50724 0.44387,1.06317 0.44387,1.66706s-0.14803,1.15664 -0.44387,1.65777l0,0.00001l0,0.00001z" fill="#1e1e1e" id="svg_10"/>
     <path stroke="null" class="cls-2" d="m33.70458,41.88392c-0.43482,-0.94817 -1.02354,-1.68199 -1.76666,-2.20171c-0.74288,-0.51923 -1.5736,-0.77909 -2.49142,-0.77909s-1.71233,0.25986 -2.45521,0.77909c-0.74288,0.51972 -1.33184,1.25036 -1.76666,2.19266c-0.43482,0.94206 -0.65234,2.06566 -0.65234,3.3701s0.22022,2.4163 0.6614,3.37034c0.44093,0.95429 1.03577,1.69423 1.78477,2.21957c0.74899,0.52559 1.57653,0.78839 2.48237,0.78839s1.70939,-0.25962 2.44616,-0.77934c0.73676,-0.51923 1.32279,-1.25623 1.7576,-2.21052c0.43506,-0.95404 0.65234,-2.07741 0.65234,-3.37034s-0.21728,-2.43075 -0.65234,-3.37915l0,0.00001l-0.00001,0zm-0.82436,6.31469c-0.34428,0.82166 -0.81261,1.4466 -1.40427,1.87529c-0.5919,0.42894 -1.26235,0.64329 -2.01135,0.64329s-1.41944,-0.21434 -2.0111,-0.64329c-0.59215,-0.42869 -1.05999,-1.05363 -1.40427,-1.87529c-0.34452,-0.82142 -0.51654,-1.8058 -0.51654,-2.95364s0.17202,-2.12586 0.51654,-2.93529c0.34428,-0.80919 0.81212,-1.42825 1.40427,-1.85719c0.59166,-0.42869 1.26211,-0.64329 2.0111,-0.64329s1.41944,0.21459 2.01135,0.64329c0.59166,0.42894 1.05999,1.05094 1.40427,1.86625c0.34428,0.8153 0.51654,1.79088 0.51654,2.92624s-0.17226,2.13222 -0.51654,2.95364l0,-0.00001z" fill="#1e1e1e" id="svg_11"/>
    </g>
   </g>
   <path stroke="null" class="cls-1" d="m78.9926,51.62279l-3.53723,0s-4.47683,-14.64292 -4.47683,-14.64292l3.51349,0.00049l4.50056,14.64269l0,-0.00025l0,-0.00001l0.00001,0zm-15.42348,-50.28873l-3.51349,-0.00049l4.47683,14.64292l3.53723,0s-4.50056,-14.64269 -4.50056,-14.64269l0,0.00025l0,0.00001l-0.00001,0zm15.49469,-0.00025l-3.53723,0l9.49296,31.05006l1.77889,-5.75142l-7.73462,-25.29864zm-12.3196,35.64629l-3.51349,-0.00049l4.47683,14.64292l3.53723,0s-4.50056,-14.64269 -4.50056,-14.64269l0,0.00025l0,0.00001l-0.00001,0zm-10.92292,-35.64629l-3.51349,-0.00049l4.47683,14.64292l3.53723,0s-4.50056,-14.64269 -4.50056,-14.64269l0,0.00025l0,0.00001l-0.00001,0zm3.17508,35.64629l-3.51349,-0.00049l4.47683,14.64292l3.53723,0s-4.50056,-14.64269 -4.50056,-14.64269l0,0.00025l0,0.00001l-0.00001,0zm-10.92292,-35.64629l-3.51349,-0.00049l4.47683,14.64292l3.53723,0s-4.50056,-14.64269 -4.50056,-14.64269l0,0.00025l0,0.00001l-0.00001,0zm23.26748,0l-3.53723,0l13.33212,43.60749l1.77889,-5.75117l0,0s-11.57379,-37.85633 -11.57379,-37.85633l0,0.00001z" fill="url(#Adsız_degrade_344)" id="svg_12"/>
  </g>
 </g>
</svg>
  );

  return (
    <Link
      component={RouterLink}
      href="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: single ? 78 : 128,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        {single ? singleLogo : fullLogo}
      </Box>
    </Link>
  );
}

Logo.propTypes = {
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(Logo);
