import { lazy } from 'react';

// ----------------------------------------------------------------------

const LandingPage = lazy(() => import('src/pages/z36/landing'));

// ----------------------------------------------------------------------

export const z36Routes = [
  {
    path: 'z36',
    children: [
      { element: <LandingPage />, index: true },
    ],
  },
];
