// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 100,
  H_DESKTOP: 150,
  H_DESKTOP_OFFSET: 150 - 36,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
