// ----------------------------------------------------------------------

export const paths = {
  // Z360
  z36: {
    root: '/z36',
  },

// Agentur
agentur: {
  about: '/agentur/ueber-mich',
  jobs: '/agentur/jobs',
  fullstack: '/agentur/jobs/fullstack-webentwickler',
  kontakt: '/agentur/kontakt',
  casestudy_cc: '/agentur/case-study/classic-company',
  casestudy_rimini: '/agentur/case-study/eiscaferimini',
},

// Services
services: {
  branding: '/leistungen/branding',
  webdesign: '/leistungen/webdesign',
  relaunch: '/leistungen/webdesign/website-relaunch',
  grafikdesign: '/leistungen/grafikdesign',
  ecommerce: '/leistungen/ecommerce',

},
// Beratung
consulting: {
  digitalization: '/beratung/digitalisierung',
  ai: '/beratung/ki-strategie',
  pm: '/beratung/projekt-management',
},
};
